import { Form } from "react-bootstrap"
import _ from "lodash"

const PositiveIntegerInput = ({ value, setValue, min, max, ...rest }) => {

	const handleChange = (e) => {
		let val = e.target.value

		// Replaces all non-digits
		val = val.replace(/\D/, "")
		const maxLength = max.toString().length
		val = val.slice(0,maxLength)
		if (val > max || val < min) {
			return
		}

		setValue(val)
	}

	const handleKeyDown = (e) => {
		if (["-", "+", "e", "E", ".", ","].includes(e.key)) {
			e.preventDefault()
		}
	}

	return (
		<Form.Control
			name="number"
			type="number"
			value={value}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			min={min ?? 0}
			max={max ?? 999}
			step={1}
			{...rest}
		/>
	)
}

export default PositiveIntegerInput