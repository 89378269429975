import { Button } from "react-bootstrap"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import Icon from "@components/Common/Icon"

const BackButton = ({ label="Palaa etusivulle", path="/" }) => {

	const { t } = useTranslation()
	const navigate = useNavigate()

	return <Button onClick={() => navigate(path)} 
		className="mb-3 shadow" 
		style={{ 
			borderRadius: 20, 
			display: "inline-flex", 
			flexDirection: "row", 
			alignItems: "center", 
			flexWrap: "nowrap", 
			whiteSpace: "nowrap", 
			columnGap: "10px",
			paddingRight: "40px",
		}}
	>
		<Icon style={{ width: "20px" }} name="arrow_backward" />
		<span style={{ flex: 1 }}>{t(label)}</span>
	</Button>
}

export default BackButton