/**
 * `Key`: value of the answer, 
 * 
 * `Value`: object with color fields
 */
export const barColorConfig = new Map(
	[[1, {
		color: "hsl(274, 100%, 34%)",
		colorLight: "hsl(274, 100%, 54%)",
	}], 
	[2, {

		color: "hsl(274, 100%, 85%)",
		colorLight: "hsl(274, 100%, 90%)",
	}],
	[3, {

		color: "hsl(178, 85%, 46%)",
		colorLight: "hsl(178, 72%, 73%)",
	}], 
	[4, {
		color: "hsl(219, 100%, 40%)",
		colorLight: "hsl(219, 66%, 71%)",
	}], 
	[5, {
		color: "hsl(219, 100%, 20%)",
		colorLight: "hsl(219, 100%, 30%)",
	}]]
)
