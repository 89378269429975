import { api } from "./api"
import _ from "lodash"
import { locResponse } from "./utils"

export const templateRoleApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getTemplateRoles: builder.query({
			query: () => ({
				url: "/templateRole",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				return result.map(doc => ({ type: "TemplateRole", id: doc._id })).concat([{type: "TemplateRole", id: "LIST"}, "Loc"])
			},
			transformResponse: locResponse
		}),
		getTemplateRole: builder.query({
			query: (id) => ({
				url: `/templateRole/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "TemplateRole", id: arg }]
			}
		}),
		createTemplateRole: builder.mutation({
			query: (body) => ({
				url: "/templateRole",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "TemplateRole", id: "LIST" }]
			}
		}),
		updateTemplateRole: builder.mutation({
			query: ({id, body}) => ({
				url: `/templateRole/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return _.compact([
					{ type: "TemplateRole", id: args.id },
					(args?.body?.createRootTemplates || args?.body?.updateRootTemplates) && { type: "RootGroup" }
				])
			}
		}),
		removeTemplateRole: builder.mutation({
			query: ({id, body}) => ({
				url: `/templateRole/${id}`,
				method: "DELETE",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return _.compact([
					{ type: "TemplateRole", id: args.id },
					args?.body?.removeAll && { type: "RootGroup" }
				])
			}
		}),
		rootGroupAddTemplateRole: builder.mutation({
			query: ({id, templateId}) => ({
				url: `/root_group/${id}/templateRole/${templateId}`,
				method: "POST"
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "RootGroup", id: args.id }]
			}
		}),
		rootGroupUpdateTemplateRole: builder.mutation({
			query: ({id, templateId, body}) => ({
				url: `/root_group/${id}/templateRole/${templateId}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "RootGroup", id: args.id }]
			}
		}),
		rootGroupRemoveTemplateRole: builder.mutation({
			query: ({ id, templateId }) => ({
				url: `/root_group/${id}/templateRole/${templateId}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "RootGroup", id: args.id }]
			}
		}),
	})
})

export const {
	useGetTemplateRolesQuery,
	useGetTemplateRoleQuery,
	useCreateTemplateRoleMutation,
	useUpdateTemplateRoleMutation,
	useRemoveTemplateRoleMutation,
	useRootGroupAddTemplateRoleMutation,
	useRootGroupUpdateTemplateRoleMutation,
	useRootGroupRemoveTemplateRoleMutation,
} = templateRoleApi
