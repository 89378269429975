import { useGetSurveyEventsQuery } from "@api/events-api"
import EventPicker from "@components/Event/Inputs/EventPicker"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import SurveyDistributionCharts from "./SurveyDistributionCharts"
import Loading from "@views/Loading"
import { useSelector } from "react-redux"
import { userSelector } from "@redux/user"

const now = new Date()

const SurveyAnswersDistributionTab = ({ pageName, groupIds }) => {
	const { t } = useTranslation()

	const { contextData } = useSelector(userSelector)
	const { data: events, isLoading, isFetching } = useGetSurveyEventsQuery({ groupIds: groupIds ?? contextData?.group, to: now, pageName })
	const [showAverages, setShowAverages] = useState(false)
	const [showAnswerDistribution, setShowAnswerDistribution] = useState(true)
	const [selectedEvent, setSelectedEvent] = useState(events?.[0] ?? null)

	useEffect(() => {
		if(events) {
			setSelectedEvent(events?.[0] ?? null)
		}
	}, [events])

	if(isLoading || isFetching) {
		return <Loading />
	}

	return (
		<div>
			<div className="mb-3" style={{ display: "flex", flexDirection: "row", flexGrow: 1 }}>
				<div style={{ marginTop: 12, cursor: "pointer", flex: 1 }}>
					<EventPicker disabled={_.isEmpty(events)} events={events ?? []} event={selectedEvent} onSelect={(event) => setSelectedEvent(event)} />
					<h6>{t("Valitse tarkasteltava kyselykerta")}</h6>
				</div>
				<div style={{ marginTop: 12, display: "flex", cursor: "pointer", marginLeft: 20 }}>
					<Form.Check
						label={t("Kysymysryhmien keskiarvot")}
						checked={showAverages}
						onChange={e => setShowAverages(e.target.checked)}
					/>
				</div>
				<div style={{ marginTop: 12, display: "flex", cursor: "pointer", marginLeft: 20 }}>
					<Form.Check
						label={t("Vastausten jakautuminen")}
						checked={showAnswerDistribution}
						onChange={e => setShowAnswerDistribution(e.target.checked)}
					/>
				</div>
			</div>
			<SurveyDistributionCharts groupIds={groupIds} event={selectedEvent} pageName={pageName} showAverages={showAverages} showAnswerDistribution={showAnswerDistribution} />
		</div>
	)
}

export default SurveyAnswersDistributionTab