/**
 * Only affects the `user.contextData` redux state. Used for controlling
 * components that are dependent `contextData.group` path value.
 * 
 * `RoleSelector` component is used for setting persisting API changes.
 */

import { setContextData, userSelector } from "@redux/user"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import { Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useGroups } from "@utils/hooks"
import { useCoachLike } from "@utils/customization/customization"
import { setSelectedProfileId } from "@redux/visualization"

const GroupContextSelect = ({ header, alwaysShow }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { contextData } = useSelector(userSelector)
	const isCoach = useCoachLike()
	const cGroups = useGroups()
	const groupDocs = cGroups

	const handleChange = e => {
		let value = e.target.value
		// Useful to have access to full object in some cases
		const groupObj = groupDocs.find((doc) => doc._id === value)
		dispatch(setContextData({ ...contextData, group: value, groupObj }))
		dispatch(setSelectedProfileId(null))
	}

	// Prefer to hide component when not a supervisory role
	if ((!isCoach && groupDocs.length <= 1) && !alwaysShow) {
		return null
	}

	return (
		<>
			{header ?? <Form.Label>{t("Tarkasteltava ryhmä")}</Form.Label>}
			{groupDocs.length > 1 ? (
				<Form.Group>
					<Form.Select
						name="group"
						value={contextData.group}
						onChange={handleChange}
					>
						{groupDocs.map(g => <option key={g._id} value={g._id}>{g.name}</option>)}
					</Form.Select>
				</Form.Group>
			) : (
				<div>{groupDocs?.[0]?.name ?? t("Ei ryhmiä")}</div>
			)}
		</>
	)
}

export default GroupContextSelect