import { api } from "./api"
import { locResponse } from "./utils"

export const eventTypeApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEventTypes: builder.query({
			query: params => ({
				url: "/eventType",
				params,
			}),
			providesTags: res => ["EventType", "Loc"].concat(res ? res?.map(o => ({ type: "EventType", id: o._id })) : []),
			transformResponse: locResponse
		}),
		getEventType: builder.query({
			query: ({ id, query }) => ({
				url: "/eventType/"+ id,
				params: query,
			}),
			providesTags: res => [{ type: "EventType", id: res?._id }]
		}),
		getEventTypeByName: builder.query({
			query: ({ name, query }) => ({
				url: "/eventType/name",
				params: { ...query, name },
			}),
			providesTags: res => [{ type: "EventType", id: res?._id }]
		}),
		createEventType: builder.mutation({
			query: (body) => ({
				url: "/eventType",
				method: "POST",
				body,
			}),
			invalidatesTags: ["EventType"]
		}),
		updateEventType: builder.mutation({
			query: ({ id, body }) => ({
				url: "/eventType/" + id,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res) => ["RootGroupClass", { type: "EventType", id: res?._id}]
		}),
		deleteEventType: builder.mutation({
			query: (id) => ({
				url: "/eventType/" + id,
				method: "DELETE",
			}),
			invalidatesTags: ["RootGroupClass", "EventType"]
		}),
		saveEventTypes: builder.mutation({
			query: (body) => ({
				url: "/eventType/multi",
				method: "POST",
				body,
			}),
			invalidatesTags: ["EventType"]
		}),
	})
})

export const { 
	useGetEventTypesQuery,
	useGetEventTypeQuery,
	useGetEventTypeByNameQuery,
	useCreateEventTypeMutation,
	useUpdateEventTypeMutation,
	useDeleteEventTypeMutation,
	useSaveEventTypesMutation
} = eventTypeApi