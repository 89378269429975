import { OverlayTrigger, Popover } from "react-bootstrap"
import { InfoCircleFill } from "react-bootstrap-icons"

// Alternative to HoverTooltip
const PopoverButton = ({ text, header, icon, size, placement, maxWidth }) => {

	const popover = (
		<Popover style={{ zIndex: 2000, maxWidth }}>
			{header && <Popover.Header as="h3">{header}</Popover.Header>}
			<Popover.Body>
				<div>
      		{text}
				</div>
			</Popover.Body>
		</Popover>
	)

	const IconComp = icon ?? InfoCircleFill

	return (
		<>
			<OverlayTrigger
				placement={placement || "right"}
				overlay={popover}
				trigger={"click"}
				style={{ maxWidth: 400 }}
			>
				<span className="pointer">
					<IconComp size={size} />
				</span>
			</OverlayTrigger>
		</>
	)
}

export default PopoverButton