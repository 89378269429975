/**
 * Store for test data visualization form state and actions.
 */
import { createSlice } from "@reduxjs/toolkit"
import { lastDayOfMonth, startOfMonth, subMonths } from "date-fns"

const KEYS = [
	"table",
	"timegraph",
	"agegroups",
	"grouplevels",
	"difference",
	"ranking",
]

// Form fields that can be omitted and cause missing parameter errors.
// There are no freeform input fields yet that cause other validation errors.
const FORM_FIELDS = [
	"groups",
	"trainees",
	"tests",
	"selectedGroup",
]

const baseFormErrObj = {}
KEYS.forEach(key => {
	baseFormErrObj[key] = {}
	FORM_FIELDS.forEach(field => {
		baseFormErrObj[key][field] = false
	})
})

const initialState = {
	isSubmitting: false,
	selectedGroup: {},
	selectedGroups: [],
	selectedType: "test",
	selectedTests: [],
	selectedSurvey: "",
	selectedTrainees: [],
	selectedSex: "",
	groupOptions: [],
	testOptions: [],
	traineeOptions: [],
	averageValue: "teamAverage",
	disableDateRange: false,
	startDate:  startOfMonth(subMonths(new Date(), 6)).toISOString(),
	endDate: lastDayOfMonth(new Date()).toISOString(),
	ageGroupRange: [9, 15],
	developmentAverage: false,
	developmentComplete: false,
	interval: "biannual",
	sampleAggregate: "best",
	showGroups: true,
	showTrainees: true,
	formErrors: baseFormErrObj,
	deltaData: [],
	deltaProtocol: "",
	deltaShowEmpty: false,
	deltaAggregate: "average",
	// For target level table use; redux state changes caused by table clicks are applied to other components' local state when needed
	targetTableDemo: "P15",
	targetTableDemoPhv: "P> + 1",
	targetTableCategory: "ageGroup",
	selectedProfileId: ""
}

export const visualizationSlice = createSlice({
	name: "visualization",
	initialState: initialState,
	reducers: {
		setIsSubmitting(state, {payload}) {
			state.isSubmitting = payload
		},
		setSelectedGroup(state, {payload}) {
			state.selectedGroup = payload
		},
		setSelectedGroups(state, {payload}) {
			state.selectedGroups = payload
		},
		setSelectedType(state, {payload}) {
			state.selectedType = payload
		},
		setSelectedTests(state, {payload}) {
			state.selectedTests = payload
		},
		setSelectedSurvey(state, {payload}) {
			state.selectedSurvey = payload
		},
		setSelectedTrainees(state, {payload}) {
			state.selectedTrainees = payload
		},
		setSelectedSex(state, {payload}) {
			state.selectedSex = payload
		},
		setGroupOptions(state, {payload}) {
			state.groupOptions = payload
		},
		setTestOptions(state, {payload}) {
			state.testOptions = payload
		},
		setTraineeOptions(state, {payload}) {
			state.traineeOptions = payload
		},
		setAverageValue(state, {payload}) {
			state.averageValue = payload
		},
		setDisableDateRange(state, {payload}) {
			state.disableDateRange = payload
		},
		setStartDate(state, {payload}) {
			state.startDate = payload
		},
		setEndDate(state, {payload}) {
			state.endDate = payload
		},
		setAgeGroupRange(state, {payload}) {
			state.ageGroupRange = payload
		},
		setDevelopmentAverage(state, {payload}) {
			state.developmentAverage = payload
		},
		setDevelopmentComplete(state, {payload}) {
			state.developmentComplete = payload
		},
		setInterval(state, {payload}) {
			state.interval = payload
		},
		setSampleAggregate(state, {payload}) {
			state.sampleAggregate = payload
		},
		setShowGroups(state, {payload}) {
			state.showGroups = payload
		},
		setShowTrainees(state, {payload}) {
			state.showTrainees = payload
		},
		setFormErrors(state, {payload}) {
			state.formErrors = payload
		},
		resetFormErrors(state, {key}) {
			if (key)
				FORM_FIELDS.forEach(field => state.formErrors[key][field] = false)
			else
				state.formErrors = baseFormErrObj
		},
		setFormError(state, {payload}) {
			const {key, field, bool} = payload
			state.formErrors[key][field] = bool
		},
		setDeltaData(state, {payload}) {
			state.deltaData = payload
		},
		setDeltaProtocol(state, {payload}) {
			state.deltaProtocol = payload
		},
		setDeltaAggregate(state, {payload}) {
			state.deltaAggregate = payload
		},
		setDeltaShowEmpty(state, {payload}) {
			state.deltaShowEmpty = payload
		},
		resetVisualizationState() {
			return initialState
		},
		setTargetTableDemo(state, {payload}) {
			state.targetTableDemo = payload
		},
		setTargetTableDemoPhv(state, {payload}) {
			state.targetTableDemoPhv = payload
		},
		setTargetTableCategory(state, {payload}) {
			state.targetTableCategory = payload
		},
		setSelectedProfileId(state, {payload}) {
			state.selectedProfileId = payload
		}
	},
})

export const { 
	setIsSubmitting,
	setSelectedGroup,
	setSelectedGroups,
	setSelectedType,
	setSelectedTests,
	setSelectedSurvey,
	setSelectedTrainees,
	setSelectedSex,
	setGroupOptions,
	setTestOptions,
	setTraineeOptions,
	setAverageValue,
	setDisableDateRange,
	setStartDate,
	setEndDate,
	setAgeGroupRange,
	setDevelopmentAverage,
	setDevelopmentComplete,
	setInterval,
	setSampleAggregate,
	setShowGroups,
	setShowTrainees,
	setFormErrors,
	resetFormErrors,
	setFormError,
	setDeltaData,
	setDeltaProtocol,
	setDeltaAggregate,
	setDeltaShowEmpty,
	resetVisualizationState,
	setTargetTableDemo,
	setTargetTableDemoPhv,
	setTargetTableCategory,
	setSelectedProfileId
} = visualizationSlice.actions

export const visualizationSelector = state => ({
	...state.visualization,
	curDemo: state.visualization.targetTableCategory === "ageGroup" ? state.visualization.targetTableDemo : state.visualization.targetTableDemoPhv
})