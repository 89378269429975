import PopoverButton from "@components/Common/PopoverButton"
import React, {useEffect, useState} from "react"
import { Modal, Table } from "react-bootstrap"
import { SortAlphaDown, SortAlphaUp, SortNumericDown, SortNumericUp } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"
import {useSendAnalyticsEventMutation} from "@api/analytics-api"
import _ from "lodash"

const getChoiceText = (choices) => {
	const choicesMerged = choices.map(c => `${c.value} = ${c.label}`).join(", ")
	return `(${choicesMerged})`
}

const QuestionAnswersModal = ({ data, show, setShow, title, questionGroup, pageName }) => {
	const { t } = useTranslation()
	if (!data) return null

	return (
		<Modal show={show} centered onHide={() => setShow(false)} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>{title ?? t("Kysymys vastaukset")}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{questionGroup?.questions?.length === 1 ? (
					<>
						<h4>{questionGroup?.questions?.[0]?.name}</h4>
						<h6>{getChoiceText(questionGroup?.questions?.[0]?.choices ?? [])}</h6>
						<SortableTableOne data={data} question={questionGroup?.questions?.[0]} />
					</>
				) :  (
					<>
						<h4>{questionGroup?.name}</h4>
						<SortableTableQuestions data={data} questionGroup={questionGroup} pageName={pageName} />
					</>
				)}
			</Modal.Body>
		</Modal>
	)
}

const SortableTableQuestions = ({ data, questionGroup, pageName }) => {
	const { t } = useTranslation()

	const [ sortBy, setSortBy ] = useState("name")
	const [ ascending, setAscending ] = useState(true)
	const [ questionId, setQuestionId ] = useState(null)
	const [analyticsEvent] = useSendAnalyticsEventMutation()

	useEffect(() => {
		!_.isEmpty(data) && analyticsEvent({ viewed: _.uniq(data.map((player) =>
			player?.profile?._id)), view: pageName })
	}, [pageName])

	if(!questionGroup) {
		return null
	}

	const clickSort = (_sortBy, id) => {
		setQuestionId(id)
		if ( sortBy === _sortBy ) {
			setAscending(!ascending)
		} else {
			setAscending(true)
			setSortBy(_sortBy)
		}
	}

	const sortedData = [...(data || [])].sort((a, b) => {
		if ( sortBy === "name" ) {
			if ( a?.profile?.name < b?.profile?.name ) return ascending ? -1 : 1
			if ( a?.profile?.name > b?.profile?.name ) return ascending ? 1 : -1
			return 0
		} else {
			const aValue = a.surveyAnswers.find(sA => sA.questionId === questionId)?.value ?? null
			const bValue = b.surveyAnswers.find(sA => sA.questionId === questionId)?.value ?? null
			if ( aValue < bValue ) return ascending ? -1 : 1
			if ( aValue > bValue ) return ascending ? 1 : -1
			return 0
		}
	})

	return (
		<div>
			<Table size="sm" hover bordered striped responsive>
				<thead>
					<tr>
						<th style={{ cursor: "pointer" }} onClick={() => clickSort("name")} className="align-middle text-center">
							{t("Pelaaja")}
							{ sortBy === "name" && <SortArrow ascending={ascending} numeric={false} /> }
						</th>
						{questionGroup?.questions?.map(q => (
							<th key={q.id} style={{ cursor: "pointer", minWidth: 200 }} className="align-middle text-center">
								<div onClick={() => clickSort("value", q.id)}>
									{q.name}
									{ sortBy === "value" && questionId === q.id && <SortArrow ascending={ascending} numeric={true} /> }
								</div>
								{q?.inputType === "multipleChoiceRadio" && <PopoverButton text={
									<>
										<h5>{t("Kysymyksen vaihtoehdot")}</h5>
										<h7>{getChoiceText(q.choices)}</h7>
									</>}
								/>}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{sortedData?.map((player, i) => {
						const name = player?.profile?.name
						return <tr key={i}>
							<td className="align-middle text-center">{name}</td>
							{questionGroup?.questions?.map(q => (
								<td key={q.id} className="align-middle text-center">
									{ player?.surveyAnswers?.find(sA => sA.questionId === q.id)?.value }
								</td>
							))}
						</tr>
					})}
				</tbody>
			</Table>
		</div>
	)
}

const SortableTableOne = ({ data, question }) => {
	const { t } = useTranslation()

	if(!question) {
		return null
	}

	const [ sortBy, setSortBy ] = useState("name")
	const [ ascending, setAscending ] = useState(true)

	const clickSort = (_sortBy) => {
		if ( sortBy === _sortBy ) {
			setAscending(!ascending)
		} else {
			setAscending(true)
			setSortBy(_sortBy)
		}
	}

	const sortedData = [...(data || [])].sort((a, b) => {
		if ( sortBy === "name" ) {
			if ( a?.profile?.name < b?.profile?.name ) return ascending ? -1 : 1
			if ( a?.profile?.name > b?.profile?.name ) return ascending ? 1 : -1
			return 0
		} else {
			const aValue = a.surveyAnswers.find(sA => sA.questionId === question.id)?.value ?? null
			const bValue = b.surveyAnswers.find(sA => sA.questionId === question.id)?.value ?? null
			if ( aValue < bValue ) return ascending ? -1 : 1
			if ( aValue > bValue ) return ascending ? 1 : -1
			return 0
		}
	})

	return (
		<Table size="m" hover bordered striped responsive>
			<thead>
				<tr>
					<th style={{ cursor: "pointer" }} onClick={() => clickSort("name")}>
						{t("Pelaaja")}
						{ sortBy === "name" && <SortArrow ascending={ascending} numeric={false} /> }
					</th>
					<th style={{ cursor: "pointer" }} onClick={() => clickSort("value")}>
						{t("Vastaus")}
						{ sortBy === "value" && <SortArrow ascending={ascending} numeric={true} /> }
					</th>
					{question?.freeFormFields?.map(ff => (
						<th >
							{ff.name}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{sortedData?.map((player, i) => {
					const freeFormFields = question?.freeFormFields ?? []
					const name = player?.profile?.name
					const questionAnswer = player?.surveyAnswers.find(sA => sA.questionId === question.id)
					const value = questionAnswer?.value
					const freeFormAnswers = questionAnswer?.freeFormFieldAnswers ?? []
					return <tr key={i}>
						<td>{name}</td>
						<td>{value}</td>
						{freeFormFields.map(ff => (
							<td>{freeFormAnswers.find(ffA => ffA.refId === ff.id)?.value}</td>
						))}
					</tr>
				})}
			</tbody>
		</Table>
	)
}

const SortArrow = ({ascending=true, numeric=true}) => {
	const size = 20
	return <span style={{ marginLeft: 8 }}>
		<>
			{!numeric && !ascending && <SortAlphaUp size={size} />}
			{!numeric && ascending && <SortAlphaDown size={size} />}
			{numeric && !ascending && <SortNumericUp size={size} />}
			{numeric && ascending && <SortNumericDown size={size} />}
		</>
	</span>
}

export default QuestionAnswersModal
