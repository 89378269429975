import _ from "lodash"
import React from "react"
import DashboardTableValueFormatter from "../TableValueFormatter"
import ClubTitleHeader from "../ClubTitleHeader"
import { useTranslation } from "react-i18next"
import { Table } from "react-bootstrap"

/** 
 * Represents actual table interface for the overview dashboard. 
*/
const ClubSupportOverviewTable = ({ data }) => {

	const { t } = useTranslation()

	const renderBody = ({ values }) => {
		const renderRuleRow = (isIndented, sectionIndex, ruleKey) => {
			const label = _.get(values, [0, "sections", sectionIndex, "values", ruleKey, "rule", "label"])
			const N_CLUBS = values.length
	
			const cols = []
			cols.push(<td key={sectionIndex+"_label"} style={{ paddingLeft: isIndented ? "8px" : 0 }}>{label}</td>)
			for (let i = 0; i < N_CLUBS; i++) {
				const actualRule = _.get(values, [i, "sections", sectionIndex, "values", ruleKey])
				cols.push(<DashboardTableValueFormatter key={sectionIndex+"_val_"+i} dataObj={actualRule} />)
			}

			return (
				<tr key={sectionIndex+"_row_"+ruleKey}>
					{cols}
				</tr>
			)
		}

		return values[0].sections.map((section, index) => {
			return <React.Fragment key={index}>
				{section?.title ? <tr style={{ fontWeight: "500", fontSize: 16 }}><td>{section.title}</td></tr> : <tr />}
				{Object.keys(section.values).map((ruleKey) => renderRuleRow(!!section?.title, index, ruleKey))}
				{/* Spacer to create gap between different sections */}
				<tr style={{ height: 20}} />
			</React.Fragment>
		})
	}

	return (
		<>
			{Object.entries(data).map(([bucketLevel, values], i) => {
				return (
					<Table key={i}>
						<colgroup>
							<col width={`${(1 / values.length - 1) * 100}%`} />
							{_.range(0, values.length).map((_, i) => <col key={i} width={"20%"} />)}
						</colgroup>
						<thead>
							<tr>
								<th style={{ fontSize: 18 }}>{t("Kori")} {bucketLevel}</th>
								{values.map((v, i) => <ClubTitleHeader key={i} club={v} />)}
							</tr>
						</thead>
						<tbody>
							{renderBody({ bucketLevel, values })}
						</tbody>
					</Table>
				)
			})}
		</>
	)
}

export default ClubSupportOverviewTable
