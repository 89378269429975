import React from "react"
import { Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Bar, BarChart, Brush, CartesianGrid, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts"

const color = "#4A72A7"
const brushWidth = 6

const QuestionsChartModal = ({ data, title, show, setShow, type = "n" }) => {
	const { t } = useTranslation()

	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip text-center" style={{ maxWidth: 600, padding: 20 }}>
					{type === "n" ? ( 
						<h4>{payload[0]?.value === 1 ? "1 pelaaja" : `${payload[0]?.value} pelaajaa`}</h4>
					) : (
						<h4>{t("Tulos")}: {payload[0]?.value}</h4>
					)}

				</div>
			)
		}   
		return null
	}

	const CustomizedAxisTick = (props) => {
		const { x, y, payload, width, visibleTicksCount, height } = props
		const textWidth = width / visibleTicksCount
		return (
			<Text x={x} y={y} width={textWidth * 0.75} height={height} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
		)
	}

	return (
		<Modal show={show} centered onHide={() => setShow(false)} size="xl">
			<Modal.Header closeButton>
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body></Modal.Body>
			<Modal.Body>
				<ResponsiveContainer height={440} className="main-bar-chart-container">
					<BarChart barGap={1} data={data} margin={{ bottom: 40 }}>
						<CartesianGrid vertical={false} />
						<XAxis
							type="category" interval={0}
							dataKey={"name"}
							tick={CustomizedAxisTick}
							height={200}
						/>
						{data?.length > brushWidth && <Brush height={30} stroke={color} endIndex={brushWidth-1} />}
						<Tooltip content={<CustomTooltip />} />
						<YAxis domain={[0, 5]} label={{ value: `(${type})`, angle: -90, position: "insideLeft" }} />
						<Bar dataKey={type} fill="hsl(178, 85%, 46%)" />
					</BarChart>
				</ResponsiveContainer>
			</Modal.Body>
		</Modal>
	)
}

export default QuestionsChartModal