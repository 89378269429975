import { useTranslation } from "react-i18next"

const { format, parseISO, isValid } = require("date-fns")
const { Col, Table, Row } = require("react-bootstrap")

/**
 * Renders row of free-form answer.
 * 
 * `nextAnswer` is nullable object at index 1 of `freeForm`-array.
 * @returns 
 */
const FreeFormAnswerRow = ({ answer, nextAnswer }) => {
	const { t } = useTranslation()

	const answerValue = answer?.questionAnswerValue?.toString()?.trim()
	if (!answerValue) { 
		return <div style={{ display: "inline", whiteSpace: "nowrap", width: "100% !important", alignItems: "center", textAlign: "center", fontSize: 24, fontWeight: "bold", marginBlock: "10px" }}>
			{t("Ei aikaisempaa kyselykertaa")}
		</div>
	}

	const achievedAnswerText = !!answer?.text && answer?.text !== "" ? answer.text.trim() : "-"
	const focusingAnswerText = !!nextAnswer?.text && nextAnswer?.text !== "" ? nextAnswer.text.trim() : "-"

	const parsedDate = parseISO(answer.date)
	const formattedDate = isValid(parsedDate) ? format(parsedDate, "dd.MM.yyyy") : "-"
	const location = answer?.location ?? t("Ei sijaintia")

	return (
		<tr>
			<td>
				<div>
					<span>{formattedDate}</span>
					<br/>
					<span>{location}</span>
				</div>
			</td>
			<td>{answerValue}</td>
			<td>{achievedAnswerText}</td>
			<td>{focusingAnswerText}</td>
		</tr>
	)
}

const FreeFormAnswersTable = ({ answers }) => {
	const { t } = useTranslation()
	const filteredAnswers = answers.filter((answerSection) => !!answerSection.freeForm)

	return (
		<Col className="mb-2">
			{filteredAnswers.map((answerSection, sectionIndex) => (
				<Col key={sectionIndex}>
					<h4>{answerSection.tag}</h4>
					<Table bordered style={{ tableLayout: "fixed" }}>
						<thead>
							<tr>
								<th>{t("Kyselykerta")}</th>
								<th>{t("Vastaus")}</th>
								<th>{t("Asiat jotka toteutuivat")}</th>
								<th>{t("Asioita joihin voisin panostaa")}</th>
							</tr>
						</thead>
						<tbody>
							{answerSection.freeForm.map(
								// i % 2 == 0 filters out duplicate rows
								(answer, i) => (i % 2 === 0) && <FreeFormAnswerRow 
									key={i}
									answer={answer} 
									nextAnswer={answerSection.freeForm?.[i + 1]} />
							)}
						</tbody>
					</Table>
				</Col>
			))}
		</Col>
	)
}

export default FreeFormAnswersTable
