import { TZ } from "@/const"
import { parseISO } from "date-fns"
import { formatInTimeZone } from "overrides"
import _ from "lodash"


export const aggregates = ["best", "average", "sum", "maximum", "minimum", "fatique_index", "even_sum", "odd_sum"]

/**
 * Determines the latest and second latest used results and their delta values.
 * 
 * @param {Object} test             Formatted test object from /statistics/brief/latestDelta/:profileId response
 * @param {String} aggregate        The used aggregate name for getting value from result array
 * @param {String} profileSex       The relevant user's sex, used for selecting sex-specific derived results.
 * @returns {Object}                Object containing latest derived results and their deltas, along with other relevant data
 */
export const getLatestDelta = (test, aggregate = "average", profileSex) => {
	let agg
	let agg2nd
	let matchedSpec

	if (test.hasSpecial) {
		const getMatch = (path) => {
			let arr = _.get(test, [path, "fullSpecialResults"])
			if (!arr)
				return null
			let sex = profileSex ?? 0
			let match = arr.find(o => o.sex === sex)
			matchedSpec = match
			if (match) {
				return match.result
			} else {
				matchedSpec = arr[0]
				return arr[0].result
			}
		}

		if (test.isGlobal) {
			agg = _.get(test, "latest.value")
			agg2nd = _.get(test, "secondLatest.value")
			matchedSpec = _.get(test, "trainingData.specialfields[0]")
		} else {
			agg = getMatch("latest")
			agg2nd = getMatch("secondLatest")
		}
	} else {
		agg = _.get(test, ["latest", "aggregates", aggregate])
		agg2nd = _.get(test, ["secondLatest", "aggregates", aggregate])
	}


	try {
		agg = +(agg?.toFixed(2))
		agg2nd = +(agg2nd?.toFixed(2))
	} catch {
		agg = NaN
		agg2nd = NaN
	}
	if (isNaN(agg) || !isFinite(agg)) // Hash used for marking invalid or non-standard values
		agg = "#"
	if (isNaN(agg2nd) || !isFinite(agg2nd))
		agg2nd = "#"

	let delta = (agg === "#" || agg2nd === "#") ? "#" : (agg - agg2nd)?.toFixed(2)
	let deltaPercent = (agg === "#" || agg2nd === "#") ? "#" : +((agg - agg2nd) / agg2nd * 100)?.toFixed(2)

	let unit = matchedSpec?.unit ?? test.unit
	let date = _.get(test, ["latest", "date"])
	date = date ? formatInTimeZone(parseISO(date), TZ, "P") : null
	let date2nd = _.get(test, ["secondLatest", "date"])
	date2nd = date2nd ? formatInTimeZone(parseISO(date2nd), TZ, "P") : null

	return { latest: agg, secondLatest: agg2nd, delta, deltaPercent, unit, matchedSpec, date, date2nd }
}

/**
 * @param {*} totalSecs 
 * @param {*} secDecimals 
 * @returns 
 */
export const minSecDisplay = (totalSecs, secDecimals) => {
	if (isNaN(totalSecs))
		return "-"
	const isNegative = totalSecs < 0
	let minutes = isNegative ? Math.ceil(totalSecs / 60) : Math.floor(totalSecs / 60)
	let seconds = secDecimals ? (totalSecs % 60).toFixed(secDecimals) : Math.floor(totalSecs % 60)

	if (isNegative) {
		// Convert to positive numbers to maintain boolean logic, add minus sign after
		minutes = minutes * -1
		seconds = seconds * -1
	}
	if (minutes && seconds) {
		return `${isNegative?"-":""}${minutes} min ${seconds} s`
	}
	if (minutes && !seconds) {
		return `${isNegative?"-":""}${minutes} min`
	}
	return `${isNegative?"-":""}${seconds} s`
}

export const formatValue = (value, decimals) => !value && value !== 0 // on zeros add decimals aswell
	? value
	: parseFloat(value) >= 10 || parseFloat(value) <= -10 ? parseFloat(value).toFixed(decimals || decimals === 0 ? decimals : 1) // By default round numbers bigger than 10 to one decimal only if no other decimals parameter is present
		: parseFloat(value).toFixed(decimals || decimals === 0 ? decimals : 2) // By default round numbers smaller than 10 to two decimals if no other decimals parameter is present

export const getStringValue = (value, decimals, unit) => {
	if (unit === "s" || unit === "S") {
		return minSecDisplay(value, decimals)
	}
	return formatValue(value, decimals) + (unit ? " " + unit : "")
}


export const countDecimals = (value) => {
	if(value == null || Math.floor(value) === value || (typeof value === "string" && !_.includes(value, "."))) return 0
	return value.toString().split(".")[1].length || 0
}


export const EXCLUDE = ["Istumapituus (PHV)", "Etäisyys pituuskasvun huipusta (PHV)"]
