import { FFAClubSupportWrapper } from "./wrappers"
import { lazy } from "react"
import { lazyRetry } from "./utils"
import { Navigate, Route } from "react-router-dom"
import { PrivateRoute } from "./wrappers"
import { MainLayout } from "./wrappers"
import FFAClubSupportDashboardOverview from "@views/FFAClubSupport/FFAClubSupportDashboardOverview"
import FFAClubSupportDashboardClub from "@views/FFAClubSupport/FFAClubSupportDashboardClub"

const ROUTE_PREFIX = "/ffa-club-support"

const FFAClubSupportPlayerAssign = lazy(() => lazyRetry(() => import("../views/FFAClubSupport/FFAClubSupportPlayerAssign"), "ffaClubSupportPlayerAssign"))

const FFAClubSupportRoute = <Route
	element={
		<PrivateRoute>
			<FFAClubSupportWrapper>
				<MainLayout fullWidth />
			</FFAClubSupportWrapper>
		</PrivateRoute>
	}
>
	<Route path={`${ROUTE_PREFIX}/player-assign`} element={<FFAClubSupportPlayerAssign />} />
	<Route path={`${ROUTE_PREFIX}/dashboard/overview`} element={<FFAClubSupportDashboardOverview />} />
	<Route path={`${ROUTE_PREFIX}/dashboard/:rootGroupId`} element={<FFAClubSupportDashboardClub />} />
</Route>

export default FFAClubSupportRoute