import { convertObjToQueryStr } from "@utils/utils"
import { api } from "./api"

export const diaryApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getDiaryStats: builder.query({
			query: ({ profileId, query }) => `/profile/${profileId}/diarystat${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return [{ type: "Profile", id: args.profileId }, "Diary", "Event"]
			}
		}),
		getDiaryStatsByWeek: builder.query({
			query: (groupId) => `/statistics/diaries/byWeek${groupId?"?groupId="+groupId:""}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		// Uses specific groupId for retrieving results
		getDiaryMatchRatingOverviewDeprecated: builder.query({
			query: ({ groupId, query }) => `/statistics/diaries/${groupId}${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		// Gets all event participants regardless of group id. Note that the backend will use currently saved profile role context.
		getDiaryMatchRatingOverview: builder.query({
			query: ({ query }) => `/statistics/diaries${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		getIntervalDiaries: builder.query({
			query: ({ query }) => ({
				url: `/profile/intervalDiary${convertObjToQueryStr(query)}`,
			}),
			providesTags: (result, error, arg) => {
				return ["Diary"]
			}
		}),
		updateIntervalDiary: builder.mutation({
			query: ({ profileId, body, query }) => ({
				url: `/profile/${profileId}/intervalDiary${convertObjToQueryStr(query)}`,
				body,
				method: "PUT"
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Diary", "Event"]
			}
		}),
		updateIntervalDiaryMultiple: builder.mutation({
			query: ({ profileId, body, query }) => ({
				url: `/profile/${profileId}/intervalDiary/multiple${convertObjToQueryStr(query)}`,
				body,
				method: "PUT"
			}),
			invalidatesTags: (result, error, arg) => {
				return ["Diary", "Event"]
			}
		}),
		getPlayerSurveyDiariesExt: builder.query({
			query: ({ graph, query }) => `/statistics/player-diaries/graphs/ext/${graph}${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary"]
			}

		}),
		getSurveyResponseTableProfiles: builder.query({
			query: ({ query }) => `/statistics/diaries/table/profiles${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		getSurveyResponseTableProfilesExt: builder.query({
			query: ({ query }) => `/statistics/diaries/table/profiles/ext${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		getSurveyResponseTableGrouped: builder.query({
			query: ({ query }) => `/statistics/diaries/table/grouped${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		getSurveyResponseTableGroupedExt: builder.query({
			query: ({ query }) => `/statistics/diaries/table/grouped/ext${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		getSurveyChartData: builder.query({
			query: ({ query }) => `/statistics/diaries/graphs/ext${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
		getEventSurveyChartData: builder.query({
			query: ({ query }) => `/statistics/diaries/graphs/event/ext${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Diary", "Event"]
			}
		}),
	})
})

export const { 
	useGetDiaryStatsQuery,
	useLazyGetDiaryStatsQuery,
	useGetDiaryStatsByWeekQuery,
	useGetDiaryMatchRatingOverviewDeprecatedQuery,
	useGetDiaryMatchRatingOverviewQuery,
	useGetIntervalDiariesQuery,
	useGetPlayerSurveyDiariesExtQuery,
	useUpdateIntervalDiaryMutation,
	useUpdateIntervalDiaryMultipleMutation,
	useGetSurveyResponseTableGroupedExtQuery,
	useGetSurveyResponseTableGroupedQuery,
	useGetSurveyResponseTableProfilesExtQuery,
	useGetSurveyResponseTableProfilesQuery,
	useGetSurveyChartDataQuery,
	useGetEventSurveyChartDataQuery
} = diaryApi