import { loc, locList } from "@utils/localization"

/**
 * `transformResponse` function for localizing response data based on
 * top-level `translations` path if one exists
 * 
 * @param {Object} response 			Response data
 * @param {Object} meta						baseQuery meta property
 * @param {Object} arg						Query args; `noLocalization` property disables localization for the query
 * 
 * @returns {Object}							Response with paths modified
 */
export const locResponse = (response, meta, arg) => {
	if (arg?.noLocalization) {
		return response
	}
	if (Array.isArray(response)) {
		return locList(response)
	} else if (typeof response === "object") {
		return loc(response)
	}
	return response
}