import { convertObjToQueryStr } from "../utils/utils"
import { api } from "./api"

export const ffaClubSupportStatisticsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getClubSupportStatisticsOverview: builder.query({
			query: (query) => ({
				url: "/statistics/club-support",
				params: { ...query, sections: true }
			}),
			providesTags: (res, error, args) => res?.map(o => ({ type: "RootGroup", id: o.rootGroup.id }))
		}),
		getClubSupportStatisticsByRootGroup: builder.query({
			query: ({ rootGroup, query }) => ({
				url: `/statistics/club-support/${rootGroup}?sections=true`,
				params: query
			}),
			providesTags: (res, error, args) => [{ type: "RootGroup", id: args.rootGroup }]
		}),
		updateRootGroupClubSupportStatistics: builder.mutation({
			query: ({ rootGroup, body }) => ({
				url: `/statistics/club-support/${rootGroup}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (res, error, args) => [{ type: "RootGroup", id: args.rootGroup }]
		}),
		getPreviousClubSupportStatisticsByRootGroup: builder.query({
			query: ({ rootGroup }) => `/statistics/club-support/${rootGroup}/archive?limit=1`,
			transformResponse: response => response[0]
			// providesTags is intentionally left out because mutations do not effect past stats. Also, transformResponse re-triggers `isLoading` state on refetch undesirably.
		}),
	})
})

export const { 
	useGetClubSupportStatisticsOverviewQuery,
	useGetClubSupportStatisticsByRootGroupQuery,
	useUpdateRootGroupClubSupportStatisticsMutation,
	useGetPreviousClubSupportStatisticsByRootGroupQuery
} = ffaClubSupportStatisticsApi
