import Loading from "@views/Loading"

// Parent must have 'position: relative'
const NoResultOverlay = ({ isLoading, noResults, text, height, width }) => {

	if (!noResults && !isLoading)
		return null
	return (
		<div style={{ 
			position: "absolute",
			width: width ?? "100%",
			height: height ?? "100%", 
			zIndex: 1, 
			backgroundColor: "rgba(255, 255, 255, 0.6)",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}}>
			<div className="d-block fw-bold" style={{ width: 200 }}>
				{noResults && !isLoading && <span>{text}</span>}
				{isLoading && <Loading />}
			</div>
		</div>
	)
}

export default NoResultOverlay