const { getNotationsByQuestionGroup, getTargetLevelColorsByNotationIndex } = require("@/constants/surveyBarColors")
const { useTranslation } = require("react-i18next")

/**
 * Produces a section for target levels on charts based on a `path`.
 * 
 * Path should be of form `surveyTitle.questionGroupLabel`
 * @param {string} path - Object path used for getting notations
 */
const TargetLevelChartsLabel = ({ path }) => {
	const { t } = useTranslation()

	const [surveyTitle, questionGroupLabel] = path.split(".")
	if (!surveyTitle || !questionGroupLabel) {
		return null
	} 

	const notations = getNotationsByQuestionGroup(surveyTitle, questionGroupLabel)
	if (!notations) {
		return null
	}

	return (
		<div>
			<span className="fw-bold">{t("Tavoitetasot")}:</span>
			{notations.map((notation, idx) => {
				const { dark: color } = getTargetLevelColorsByNotationIndex(idx)
				const colorLabel = ["Sininen", "Turkoosi", "Violetti"][idx]
				return (
					<span key={idx} style={{ display: "block", fontWeight: "bold", color }}>{colorLabel}: {notation}</span>
				)
			})}
		</div>
	)
}

export default TargetLevelChartsLabel
