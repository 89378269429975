import {
	getNotationsByQuestionGroup,
	parseNotationToValue,
} from "@/constants/surveyBarColors"
import { isNotEmpty } from "@utils/utils"
import { formatValue } from "@utils/visualization"
import { format, isValid } from "date-fns"
import _ from "lodash"
import React from "react"
import { useTranslation } from "react-i18next"
import {
	Legend,
	PolarAngleAxis,
	PolarGrid,
	PolarRadiusAxis,
	Radar,
	RadarChart,
	ResponsiveContainer,
	Tooltip,
} from "recharts"
import TargetLevelChartsLabel from "../TargetLevelChartsLabel"
import useLegendGraphToggling from "../useLegendGraphToggling"

const constColors = require("@/constants/constColors.json")

const RADAR_PROPS = {
	previous: {
		fill: "#12335d",
		fillOpacity: 0.5,
	},
	latest: {
		// New colors here, make sure Eerikkilä approves them
		stroke: "#1d4f91",
		fill: "#1d4f91",
		fillOpacity: 0.5,
	},
	targetLevel: {
		// fill: "#1455cb",
		fill: "none",
		stroke: constColors.BlueMinus40,
		strokeWidth: 2,
		fillOpacity: 0.5,
	},
}

const SIZE = 330

const SurveyRadarGraph = ({ data, title }) => {
	const { t } = useTranslation()
	const {
		barState,
		handleLegendClick,
		handleLegendMouseEnter,
		handleLegendMouseLeave,
		isHidden,
		isHovered,
	} = useLegendGraphToggling()

	if (_.isEmpty(data)) {
		return (
			<div className="text-center">
				<h4 className="text-center mt-4">{title}</h4>
				<h5>
					{t("Yhtä tulosta ei löytynyt ryhmälle {{ title }} kyselystä", {
						title,
					})}
				</h5>
			</div>
		)
	}

	// Associate this radar graph with target levels if available
	// Temporary hack
	for (let i = 0; i < data.length; i++) {
		const obj = data[i]

		const notations = getNotationsByQuestionGroup(title, obj.name)

		if (notations) {
			// Find first notation that isn't empty string or null
			const notation = _.find(notations, isNotEmpty)
			const newData = _.set(
				_.cloneDeep(data),
				`${i}.targetLevel.value`,
				parseNotationToValue(notation)
			)
			data = [...newData]
		}
	}

	// Custom component is used due to `formatter` prop in tooltip not being called on null values
	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			// Latest is guaranteed to exist
			const latest = payload[0]
			const latestPayload = latest.payload.latest

			const previous = payload?.[1]
			const previousPayload = previous?.payload?.previous

			const questionGroupName = payload[0]?.payload?.name

			return (
				<div
					style={{
						textAlign: "center",
						backgroundColor: "white",
						padding: 20,
						minWidth: 200,
						maxWidth: 400,
						borderWidth: 1,
					}}
				>
					<div style={{ color: "black" }}>
						<span style={{ color: RADAR_PROPS.latest.fill }}>
							{isValid(new Date(latestPayload?.startTime)) &&
                format(new Date(latestPayload?.startTime), "dd.MM.yyyy")}
						</span>
					</div>
					{latestPayload?.groupName && (
						<div style={{ color: "black" }}>
							<span>
								{t("Ryhmä")}: {latestPayload?.groupName}
							</span>
						</div>
					)}
					{latestPayload?.location && (
						<div style={{ color: "black" }}>
							<span>{latestPayload.location}</span>
						</div>
					)}
					<br />
					<div style={{ color: "black" }}>
						<span style={{ fontWeight: "bold" }}>
							{t("Tulos")}: {formatValue(latestPayload?.value, 2)}
						</span>
					</div>
					<div style={{ color: "black" }}>
						<span style={{ fontWeight: "bold" }}>n = {latestPayload?.n}</span>
					</div>
					{previousPayload && (
						<>
							<br />
							<div style={{ color: "black" }}>
								<span style={{ color: RADAR_PROPS.previous.fill }}>
									{isValid(new Date(previousPayload?.startTime)) &&
                    format(new Date(previousPayload?.startTime), "dd.MM.yyyy")}
								</span>
							</div>
							{previousPayload?.groupName && (
								<div style={{ color: "black" }}>
									<span>
										{t("Ryhmä")}: {previousPayload?.groupName}
									</span>
								</div>
							)}
							{previousPayload?.location && (
								<div style={{ color: "black" }}>
									<span>{previousPayload.location}</span>
								</div>
							)}
							<br />
							<div style={{ color: "black" }}>
								<span style={{ fontWeight: "bold" }}>
									{t("Tulos")}: {formatValue(previousPayload?.value, 2)}
								</span>
							</div>
							<div style={{ color: "black" }}>
								<span style={{ fontWeight: "bold" }}>
                  n = {previousPayload?.n}
								</span>
							</div>
						</>
					)}
					<br />
					<TargetLevelChartsLabel path={`${title}.${questionGroupName}`} />
				</div>
			)
		}
		return null
	}

	return (
		<div>
			<h4 className="text-center mt-4">{title}</h4>
			<ResponsiveContainer height={SIZE}>
				<RadarChart
					outerRadius="80%"
					height={SIZE}
					width={SIZE}
					data={data}
					margin={{ left: 100, right: 100 }}
				>
					<Tooltip
						formatter={(v) => (v === 0 ? t("Ei tulosta") : v)}
						filterNull={false}
						content={<CustomTooltip />}
						position={{ y: -250 }}
						wrapperStyle={{ outlineColor: "gray", outlineWidth: 1 }}
					/>

					<PolarGrid outerRadius={"100%"} />
					<PolarAngleAxis
						dataKey="name"
						tickFormatter={(label) =>
							_.truncate(label, { length: 40, omission: "..." })
						}
					/>
					<PolarRadiusAxis
						angle={90}
						domain={[0, 5]}
						ticks={[1, 2, 3, 4, 5]}
						axisLine={false}
						tick={{ fill: "black" }}
					/>
					<Legend
						onClick={handleLegendClick}
						onMouseEnter={handleLegendMouseEnter}
						onMouseLeave={handleLegendMouseLeave}
					/>

					<Radar
						name={t("Tavoitetasot")}
						hide={isHidden("targetLevel.value")}
						dataKey="targetLevel.value"
						{...RADAR_PROPS.targetLevel}
					/>

					{data?.[0]?.previous && (
						<Radar
							hide={isHidden("previous.value")}
							name={
								isValid(new Date(data[0]?.previous?.startTime))
									? format(new Date(data[0]?.previous?.startTime), "dd.MM.yyyy")
									: ""
							}
							dataKey="previous.value"
							{...RADAR_PROPS.previous}
						/>
					)}

					<Radar
						hide={isHidden("latest.value")}
						name={
							isValid(new Date(data[0]?.latest?.startTime))
								? format(new Date(data[0]?.latest?.startTime), "dd.MM.yyyy")
								: ""
						}
						dataKey="latest.value"
						{...RADAR_PROPS.latest}
					/>
				</RadarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default SurveyRadarGraph
