/**
 * Reusable implementation of React Bootstrap Collapse
 */

import React, { useState } from "react"
import { Collapse, Stack } from "react-bootstrap"
import { CaretDownFill, CaretRightFill, ChevronDown, ChevronRight } from "react-bootstrap-icons"
import { useTranslation } from "react-i18next"

const CollapseWrap = ({
	children,
	renderHeader,
	headerAs,
	iconAs,
	hide,
	titleText,
	titleProps = {},
	iconProps = {},
	bodyProps = {},
	open,	// Controlled open state
	setOpen, // Controlled open state
	defaultExpanded,
	disabled,
	useCaret,
	iconSize,
	endComponent,
	...rest 
}) => {

	const { t } = useTranslation()

	const [show, setShow] = useState(!!defaultExpanded)

	const handleClick = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (setOpen) {
			setOpen(!open)
		} else {
			setShow(!show)
		}
	}

	const HeaderComp = headerAs ?? "div"
	const IconComp = iconAs ?? "div"

	const titleContent = (
		<>
			<HeaderComp>
				<Stack className={disabled?"":"pointer"} direction="horizontal" gap={2} onClick={e => disabled?null:handleClick(e)} {...rest}>
					{renderHeader ?
						renderHeader()
						:
						<div {...titleProps}>{titleText ?? t("Otsikko")}</div>
					}
					{!hide && !disabled && <IconComp>
						{(open ?? show) ?
							(useCaret ? <CaretDownFill size={iconSize} {...iconProps} /> : <ChevronDown size={iconSize} {...iconProps} />)
							: (useCaret ? <CaretRightFill size={iconSize} {...iconProps} /> : <ChevronRight size={iconSize} {...iconProps} />)}
					</IconComp>}
					<div className="ms-auto">
						{endComponent}
					</div>
				</Stack>
			</HeaderComp>
		</>)

	return (
		<>
			{titleContent}
			{!hide && <Collapse in={(open ?? show)}>
				<div {...bodyProps}>
					{children}
				</div>
			</Collapse>}
		</>
	)
}

export default CollapseWrap