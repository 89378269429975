import { 
	useGetClubSupportStatisticsByRootGroupQuery,
	useGetPreviousClubSupportStatisticsByRootGroupQuery,
	useUpdateRootGroupClubSupportStatisticsMutation
} from "@api/ffaClubSupportStatistics-api"
import Loading from "@views/Loading"
import _ from "lodash"
import ClubSupportTable from "../../components/FFAClubSupport/Dashboard/Club/ClubTable"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import BackButton from "../../components/Charts/BackButton"
import { Stack, Button } from "react-bootstrap"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { userSelector } from "@redux/user"
import { useSelector } from "react-redux"
import InvalidAccess from "@components/Common/InvalidAccess"
import { useIsCoach } from "@utils/customization/customization"

const FFAClubSupportDashboardClub = () => {

	const { isAdmin, isPlainAdmin } = useSelector(userSelector)
	const isCoach = useIsCoach()

	const { t } = useTranslation()

	const params = useParams()

	const [edit, setEdit] = useState(false)
	const [value, setValue] = useState({})

	const { data: statistics, isLoading: isStatisticsLoading } = useGetClubSupportStatisticsByRootGroupQuery(
		{ rootGroup: params.rootGroupId }
	)

	const { data: previous, isLoading: isPreviousLoading } = useGetPreviousClubSupportStatisticsByRootGroupQuery(
		{ rootGroup: params.rootGroupId }
	)

	const [updateValues, { isLoading: updateLoading }] = useUpdateRootGroupClubSupportStatisticsMutation()

	const getPickedData = () => {
		const obj = _.assign({}, ...statistics.sections.map(s => s.values))
		const adminAssignObj = _.entries(obj).reduce((a,b) => {
			const [k,v] = b
			if (v.rule.adminAssigns) {
				_.set(a, k, v.value)
			}
			return a
		}, {})
		return adminAssignObj
	}

	useEffect(() => {
		if (statistics) {
			setValue(getPickedData())
		}
	}, [statistics])


	const handleEditButtonClick = () => {
		if (edit) {
			setValue(getPickedData())
		}
		setEdit(!edit)
	}

	const handleSubmit = () => {
		//alert(JSON.stringify(value))
		updateValues({ rootGroup: params.rootGroupId, body: value }).unwrap().then(r => {
			setEdit(false)
			toast.success(t("Muutokset tallennettu"))
		}).catch(err => {
			toast.error(err.data?.message ?? err.data ?? err.message ?? err)
		})
	}

	if (isStatisticsLoading || isPreviousLoading) {
		return <Loading />
	}

	if (!isCoach && !isPlainAdmin) {
		return <InvalidAccess />
	}

	return (
		<div style={{ width: "100%" }}>
			{isAdmin ? 
				<BackButton label={t("Palaa yleiskatsastukseen")} path="/ffa-club-support/dashboard/overview" /> :
				<BackButton label={t("Palaa etusivulle")} path="/" />
			}
			<Stack direction="horizontal" gap={2}>
				<div>
					<h1>{t("Seuratukimalli Dashboard")}</h1>
					<h6 className="mb-3">{ statistics?.rootGroup.name }</h6>
				</div>
				{isAdmin && <Button onClick={handleEditButtonClick} variant={edit ? "secondary" : "primary"} className="ms-auto">
					{edit ? t("Peru muokkaus") : t("Muokkaa")}
				</Button>}
				{edit && <Button disabled={updateLoading} onClick={handleSubmit}>{t("Tallenna muutokset")}</Button>}
			</Stack>
			<ClubSupportTable data={statistics} previous={previous} value={value} setValue={setValue} edit={edit} />	
		</div>
	)
}

export default FFAClubSupportDashboardClub
