import { userSelector } from "@redux/user"
import Loading from "@views/Loading"
import { format, parseISO } from "date-fns"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useGetPlayerSurveyDiariesExtQuery } from "@api/diary-api"
import { Col, Container, Row, Table } from "react-bootstrap"
import { ChevronDown, ChevronRight } from "react-bootstrap-icons"
import { visualizationSelector } from "@redux/visualization"
import SurveyContainer from "./SurveyContainer"

// If this is gotten from backend, likely means that the graph is not present in `pageCustomizations` of operational class
const NO_GRAPH_CUSTOMIZATION_SETUP_MSG = "Invalid graph name given"

/**
 * Queries and renders individual player's question answers and free form answers.
 * 
 * Question answers are visualized as a graph, and free form answers as a datatable.
 * 
 * Uses `visualizationSelector` to check if state has a player selected as visualization target.
 * @returns 
 */
const SurveyChangeTab = ({ pageGraph }) => {
	const { t } = useTranslation()
	const { profile, contextData } = useSelector(userSelector)
	const { selectedProfileId } = useSelector(visualizationSelector)

	const { data, error, isFetching, isLoading, refetch: refetchDiaries } = useGetPlayerSurveyDiariesExtQuery({
		graph: pageGraph,
		query: { player: selectedProfileId ?? null }
	})

	// Handle updating chart data when rootgroup context changes
	useEffect(() => {
		refetchDiaries()
	}, [contextData?.rootGroup])

	if ( isLoading || isFetching) return <Loading />

	if (error) {
		if (error.data === NO_GRAPH_CUSTOMIZATION_SETUP_MSG || error.status === 404){
			// Add a box with height of 20px to act as a visual separator when empty
			return <div style={{ height: "20px" }} />
		} else {
			return <div className="text-danger">{JSON.stringify(error.data)}</div>
		}
	}

	return (
		<div className="shadow-sm p-4">
			{data.map((survey, index) => <SurveyContainer key={index} survey={survey} />)}
		</div>
	)
}

export default SurveyChangeTab
