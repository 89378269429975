/**
 * This file documents target level thresholds by a given survey title and question group. Multiple utility functions used to benefit on that data are included.
 * 
 * These rely on "notations" which are mathematical strings used in documentation.
 * Examples for valid notations (notice irrelevancy of using either comma or dot): `<1.5`, `1,5-1.7`, `1.5-1,9`, `>1,4`
 * 
 * Notations are always in this order: [blue, turqoise, purple]. 
 */

import { gt, inRange, lt } from "lodash"

export const notationByQuestionGroupMap = {
	["Tavoiteorientaatio"]: {
		["Tehtäväsuuntautunut"]: [">4,7", "4-4,7", "<4"],
		["Kilpailusuuntautunut"]: [">4,2", "3,2-4,2", "<3,2"]
	},
	["Motivaatiokysely"]: {
		"Amotivaatio": ["<1", "1-1,5", ">1,5"],
		"Ulkoinen motivaatio": ["<1", "1-1,8", ">1,8"],
		"Pakotettu säätely": ["<1", "1-2,5", ">2,5"],
		"Tunnistettu säätely": [">4,3", "3,5-4,3", "<3,5"],
		"Sisäinen motivaatio": [">4,8", "4-4,8", "<4"],
	},
	["Koetut pelitaidot"]: {
		"Hyökkäystaidot": [">4,1", "3,6-4,1", "<3,6"],
		"1 vs 1 taidot": [">4", "3,2-4", "<3,2"],
		"Puolustustaidot": [">4,3", "3,7-4,3", "<3,7"],
	},
	["Pelaajan kokonaisvaltainen toiminta"]: {
		"Teknis-taktinen": [">3,49", "2,51-3,49", "<2,51"],
		"Fyysinen": [">3,49", "2,51-3,49", "<2,51"],
		"Psyykkinen": [">3,49", "2,51-3,49", "<2,51"],
		"Sosiaalinen": [">3,49", "2,51-3,49", "<2,51"],
		"Harjoittelu": [">3,49", "2,51-3,49", "<2,51"],
		"Pelit": [">3,49", "2,51-3,49", "<2,51"],
		"Uni": [">3,49", "2,51-3,49", "<2,51"],
		"Ravinto": [">3,49", "2,51-3,49", "<2,51"],
		"Perhe/Ystävät": [">3,49", "2,51-3,49", "<2,51"],
		"Koulu": [">3,49", "2,51-3,49", "<2,51"], 
		"Kokonaiskuormittuminen": [">3,49", "2,51-3,49", "<2,51"],
	},
	["Motivaatioilmasto"]: {
		"Tehtäväsuuntautunut": [">4.8", "4.2-4.8", "<4.2"],
		"Kilpailusuuntautunut": [">3.5", "2.3-3.5", "<2.3"],
		"Autonomiaa tukeva": [">4.5", "4-4.5", "<4"],
		"Sosiaalinen yhteenkuuluvuus":  [">4.5", "4-4.5", "<4"],
		"Harjoituksista nauttiminen": [">4.8", "4.1-4.8", "<4.1"],
		"Voittamista tukeva": [">4", "3-4", "<3"],
		"Psykologinen turvallisuus": [">4", "3-4", "<3"],
		"Harjoituksen laatutekijät": [">4", "3-4", "<3"],
	},
	["Meidän voittavat toimintatavat"]:  {
		"Motivaatio": [">4"],
		"Itseluottamus": [">4"],
		"Itsetuntemus": [">4"],
		"Keskittyminen": [">4"],
		"Tavoittellisuus": [">4"],
		"Elämä urheilijana": [">4"]
	},
	["Voittavat toimintatavat"]:  {
		"Motivaatio": [">4"],
		"Itseluottamus": [">4"],
		"Itsetuntemus": [">4"],
		"Keskittyminen": [">4"],
		"Tavoittellisuus": [">4"],
		"Elämä urheilijana": [">4"]
	},
	["Voittavat tavat"]:  {
		"Motivaatio": [">4"],
		"Itseluottamus": [">4"],
		"Itsetuntemus": [">4"],
		"Keskittyminen": [">4"],
		"Tavoitteellisuus": [">4"],
		"Elämä urheilijana": [">4"]
	}
}

/**
 * Parses a `notation` (e.g. `<1.7` or `4-4.7`)
 * Returns function that takes in a value and returns if that value matches `notation`'s condition
 * 
 * @param {string} notation
 */
const parseNotationToMatcher = (notation) => {
	const parseNotationStringValue = (strValue) => parseFloat(strValue.replace(/,/g, "."))
	if (notation.startsWith("<")) {
		const notationValue = parseNotationStringValue(notation.slice(1))
		return (v) => lt(v, notationValue)
	}

	if (notation.startsWith(">")) {
		const notationValue = parseNotationStringValue(notation.slice(1))
		return (v) => gt(v, notationValue)
	}

	if (notation.includes("-")) {
		const [num1, num2] = notation.split("-").map(parseNotationStringValue)
		return (v) => inRange(v, num1, num2)
	}

	throw new Error(`Unexpected notation: "${notation}"`)
}

/** 
 * @public
 * Useful for getting value of notation outside this file. 
 * 
*/
export const parseNotationToValue = (notation) => {
	const parseNotationStringValue = (strValue) => parseFloat(strValue.replace(/,/g, "."))
	if (notation.startsWith("<")) { 
		const notationValue = parseNotationStringValue(notation.slice(1))
		return notationValue
	}

	if (notation.startsWith(">")) {
		const notationValue = parseNotationStringValue(notation.slice(1))
		return notationValue
	}

	if (notation.includes("-")) {
		const [num1, num2] = notation.split("-").map(parseNotationStringValue)
		return [num1, num2]
	}

	throw new Error(`Unexpected notation: "${notation}"`)
}

const BLUE_LIGHT = "#96b2e8"
const BLUE_DARK = "#0048cc"
const TURQOISE_LIGHT = "#a1eeec"
const TURQOISE_DARK = "#11d9d2"
const PURPLE_LIGHT = "#eacffd"
const PURPLE_DARK = "#deb3ff"

/** Public object for target level threshold colors, can be used by graph drawers for example */
export const SURVEY_TARGET_LEVEL_COLORS = { BLUE: { light: BLUE_LIGHT, dark: BLUE_DARK },
	TURQOISE: { light: TURQOISE_LIGHT, dark: TURQOISE_DARK },
	PURPLE: { light: PURPLE_LIGHT, dark: PURPLE_DARK }}

/** Color used when a color could not be extracted from a notation */
const FALLBACK_COLOR = {
	light: TURQOISE_LIGHT,
	dark: TURQOISE_LIGHT
}

/**
 * Returns object with dark and light colors for a color theme (blue, purple, etc.) by index.
 * 
 * First index of notation always means blue and so on.
 * 
 * Dark colors are used by bars depicting latest results, and light colors by previous results.
 * 
 * @param {*} idx 
 * @returns 
 */
export const getTargetLevelColorsByNotationIndex = (idx) => {
	switch (idx) {
	case 0: return SURVEY_TARGET_LEVEL_COLORS.BLUE
	case 1: return SURVEY_TARGET_LEVEL_COLORS.TURQOISE
	case 2: return SURVEY_TARGET_LEVEL_COLORS.PURPLE
	}
}

/** Returns question group maps by survey title. Can return `null` if there are no target levels associated with survey title. */
export const getQuestionGroupMapsByTitle = (title) => {
	const entries = Object.entries(notationByQuestionGroupMap)
	return entries.find(([surveyBaseTitle, _]) => title.startsWith(surveyBaseTitle))?.[1] || null
}

/** Returns question group maps by survey title. Can return `null` if there are no target levels associated with survey title. */
export const getNotationsByQuestionGroup = (title, questionGroup) => {
	const entries = Object.entries(notationByQuestionGroupMap)
	return entries.find(([surveyBaseTitle, _]) => title.startsWith(surveyBaseTitle))?.[1]?.[questionGroup] || null
}

/**
 * Gets target level color thresholds by using survey's title and a question group name.
 * Correct thresholds are found by utilizing `title´'s `startsWith`-method.
 * 
 * `v` is used for getting actual color the bar should be colored with depending on it's value against the threshold notations.
 * @param {string} title 
 * @param {string} questionGroupName 
 * @param {number} v 
 * @returns 
 */
export const getTargetLevelColorForSurveyBar = (title, questionGroupName, v, useFallbackColor = true) => {
	/** Returned when notation finding process was unsuccesful */
	const fallbackReturnVal = useFallbackColor ? FALLBACK_COLOR : null

	const questionGroupsMap = getQuestionGroupMapsByTitle(title)
	if (!questionGroupsMap) {
		return fallbackReturnVal
	}

	const notations = questionGroupsMap?.[questionGroupName]
	if (!notations) {
		return fallbackReturnVal
	}

	const correctNotationIdx = notations.findIndex((notation) => {
		const matcher = parseNotationToMatcher(notation)
		return matcher(v)
	})

	// Notation should also never be more than 2 when using 0-indexing
	if (correctNotationIdx === -1 || correctNotationIdx > 2) {
		return fallbackReturnVal
	}

	return getTargetLevelColorsByNotationIndex(correctNotationIdx)
}

