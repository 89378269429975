import _ from "lodash"

import { SURVEY_TARGET_LEVEL_COLORS, parseNotationToValue, getNotationsByQuestionGroup } from "@/constants/surveyBarColors"

// This color is used in visual definitions for this feature
const STROKE_COLOR = SURVEY_TARGET_LEVEL_COLORS.BLUE.dark
const STROKE_WIDTH = 1
// Makes line dotted
const STROKE_DASHARRAY = "3 3"

/** 
 * Uses `recharts`'s `Customized`-component to draw a line representing an optimal target level threshold for each bar 
 * 
 * Props should be spreaded from `Customized`; but will require these custom props as well: `surveyTitle` and `maxDomain`.
 * 
 * `surveyTitle` is used for getting notations, and `maxDomain` is used for calculating the height of a single `yAxis` tick.
 */
const CustomizedOptimalTargetLevelLine = (props) => {
	// Get actual non-spreaded props for this component
	const { surveyTitle, maxDomain } = props

	const heightOfYTick = Math.floor(props.yAxisMap[0].height / maxDomain)
	/** Calculated bottom Y position of actual chart container; it appears this is not provided by recharts */
	const chartBottomY = props.margin.top + props.yAxisMap[0].height
	/** Used for calculating width of line; else on single series bar charts length is too high */
	const graphicalItemsCount = props.formattedGraphicalItems.length

	// Get only first. Mapping over all items would produce lines that are not continous; looking very ugly
	// The drawback is that in graphs with only one bar series, the line is twice as long as it should be. This is why
	// count of the items is computed on earlier line, and it ensures the line width is correct in all contexts
	return _.first(props.formattedGraphicalItems).props.data.map((bar) => {
		const { width, x, name: questionGroupName } = bar

		// Get actual notation value so we know where to draw line
		const optimalNotation = _.first(getNotationsByQuestionGroup(surveyTitle, questionGroupName))
		if (!optimalNotation) return null
		const optimalTargetLevelValue = parseNotationToValue(optimalNotation)

		// Compute parameters for `line`
		const lineWidth = width * graphicalItemsCount
		const lineY = chartBottomY - (heightOfYTick * optimalTargetLevelValue)
		const lineX1 = x
		const lineX2 = x + lineWidth

		return <svg>
			<line 
				style={{ stroke: STROKE_COLOR, strokeWidth: STROKE_WIDTH, strokeDasharray: STROKE_DASHARRAY }} 
				x1={lineX1} 
				y1={lineY} 
				x2={lineX2} 
				y2={lineY} />
		</svg> 
	})
}

export default CustomizedOptimalTargetLevelLine
