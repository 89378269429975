import React from "react"
import { Bar, BarChart, CartesianGrid, Cell, Cross, Customized, Legend, Line, Rectangle, ReferenceLine, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { formatValue } from "@utils/visualization"
import { format, isValid } from "date-fns"
import { getTargetLevelColorsByNotationIndex, getTargetLevelColorForSurveyBar, notationByQuestionGroupMap, surveyBarColors, getNotationsByQuestionGroup, parseNotationToValue, SURVEY_TARGET_LEVEL_COLORS } from "@/constants/surveyBarColors"
import TargetLevelChartsLabel from "../TargetLevelChartsLabel"
import { prop } from "lodash/fp"
import CustomizedOptimalTargetLevelLine from "../CustomizedOptimalTargetLevelLine"

// HSL = Hue, Saturation, Lightness
const legendConfig = [
	{
		color: "hsl(274, 100%, 34%)",
		colorLight: "hsl(274, 100%, 54%)",
		text: "1",
		value: 1
	},
	{
		color: "hsl(274, 100%, 85%)",
		colorLight: "hsl(274, 100%, 90%)",
		text: "2",
		value: 2
	},
	{
		color: "hsl(178, 85%, 46%)",
		colorLight: "hsl(178, 72%, 73%)",
		text: "3",
		value: 3
	},
	{
		color: "hsl(219, 100%, 40%)",
		colorLight: "hsl(219, 66%, 71%)",
		text: "4",
		value: 4
	},
	{
		color: "hsl(219, 100%, 20%)",
		colorLight: "hsl(219, 100%, 30%)",
		text: "5",
		value: 5
	},
]

let tooltip

const SurveyBarGraph = ({ data, title, previous = true, onClick, transformAxis = false, minDomain = 0, maxDomain = 5 }) => {
	const { t } = useTranslation()

	if (_.isEmpty(data)) {
		return <div className="text-center">
			<h4 className="text-center mt-4">{title}</h4>
			<h5>{t("Yhtä tulosta ei löytynyt ryhmälle {{ title }} kyselystä", { title })}</h5>
		</div>
	}

	// Custom component is used due to `formatter` prop in tooltip not being called on null values
	const CustomTooltip = (props) => {
		const { active, payload, label: questionGroupLabel } = props
		if (!active || !payload || !payload?.length) {
			return null
		}

		const correctPayload = payload?.[0]?.payload?.[tooltip]
		if(!correctPayload) {
			return null
		}

		return (
			<div className="shadow-sm" style={{ textAlign: "center", background: "white", padding: 20, minWidth: 200, maxWidth: 400 }}>
				{correctPayload?.groupName && <div style={{ color: "black" }}>
					<span>{t("Ryhmä")}: {correctPayload?.groupName}</span>
				</div>}
				<div style={{ color: "black" }}>
					<span>{isValid(new Date(correctPayload?.startTime)) && format(new Date(correctPayload?.startTime), "dd.MM.yyyy")}</span>
				</div>
				{correctPayload?.location && <div style={{ color: "black" }}>
					<span>{correctPayload.location}</span>
				</div>}
				<br />
				<div style={{ color: "black" }}> 
					<span style={{ fontWeight: "bold" }}>{t("Tulos")}: {formatValue(correctPayload?.value, 2)}</span>
				</div>
				<div style={{ color: "black" }}>
					<span style={{ fontWeight: "bold" }}>n = {correctPayload?.n}</span>
				</div>
				<br/>
				<TargetLevelChartsLabel path={`${title}.${questionGroupLabel}`} />
			</div>
		)
	}

	const CustomizedAxisTickTransform = ({ x, y, payload }) => {
		return (
			<g transform={`translate(${x},${y})`}>
				<text className="recharts-cartesian-axis-tick-value growth-name"
					x={0}
					y={0}
					dy={16}
					textAnchor="end"
					fill="#666"
					transform="rotate(-55)">
					{payload.value}
				</text>
			</g>
		)
	}

		
	const CustomizedAxisTick = (props) => {
		const { x, y, payload } = props
		return (
			<Text x={x} y={y} width={150} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
		)
	}

	return (
		<div>
			<h4 className="text-center mt-4">{title}</h4>
			<ResponsiveContainer height={330} className="main-bar-chart-container">
				<BarChart barGap={1} data={data} onClick={onClick ? (e) => onClick(e) : null}>
					<XAxis
						type="category" interval={0}
						dataKey={"name"}
						tick={transformAxis ? CustomizedAxisTickTransform : CustomizedAxisTick}
						height={transformAxis ? 175 : 100} />
					<YAxis
						interval={0}
						tickCount={maxDomain + 1}
						ticks={_.range(minDomain, maxDomain + 1)} />
					<Tooltip
						filterNull={false}
						content={<CustomTooltip />}
						cursor={{fill: "transparent"}}
						position={{ y: -150 }}
					/>

					{previous && <Bar dataKey={"previous.value"} onMouseOver={() => tooltip="previous"}>
						{data.map((d, i) =>  {
							return <Cell key={i} fill={getTargetLevelColorForSurveyBar(title, d.name, d?.previous?.value)?.light}></Cell>
						})}
					</Bar>}
					<Bar dataKey={"latest.value"} onMouseOver={() => tooltip="latest"}>
						{data.map((d, i) => {
							return <Cell key={i} fill={getTargetLevelColorForSurveyBar(title, d.name, d?.latest?.value)?.dark}></Cell>
						})}
					</Bar>

					{/* Draws line indicating optimal target level for each bar */}
					<Customized component={(rechartsProps) => 
						<CustomizedOptimalTargetLevelLine 
							surveyTitle={title} 
							maxDomain={maxDomain} 
							{...rechartsProps} 
						/>}
					/>
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default SurveyBarGraph
