import { useGetEventSurveyChartDataQuery } from "@api/diary-api"
import CollapseWrap from "@components/Common/CollapseWrap"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SurveyDistributionBarGraph from "./SurveyDistributionBarGraph"
import SurveyBarGraph from "./SurveyBarGraph"
import QuestionsChartModal from "./QuestionsChartModal"
import QuestionAnswersModal from "./QuestionAnswersModal"
import { useSelector } from "react-redux"
import { userSelector } from "@redux/user"
import Loading from "@views/Loading"

const getCorrectValues = (s) => {
	if(s.data?.groupsN) {
		return s.data?.groupsN
	} else if(s.data?.questionsN) {
		return [{ questions: s.data?.questionsN }]
	} else {
		return []
	}
}

const now = new Date().toISOString()

const SurveyDistributionCharts = ({ showAverages, showAnswerDistribution, event, pageName, groupIds }) => {
	const { t } = useTranslation()
	const { contextData } = useSelector(userSelector)
	const { data, isLoading, isFetching } = useGetEventSurveyChartDataQuery({ query: { id: event?._id, groupIds: groupIds ?? contextData?.group, to: now, pageName } }, { skip: !event, refetchOnMountOrArgChange: true })

	const [show, setShow] = useState(false)
	const [selected, setSelected] = useState({ title: "", data: [] })
	const [show2, setShow2] = useState(false)
	const [selected2, setSelected2] = useState({ title: "", data: [] })
	const [show3, setShow3] = useState(false)
	const [selected3, setSelected3] = useState({ title: "", data: [], questionGroup: [] })

	if(isLoading || isFetching) {
		return <Loading />
	}

	return (
		<div>
			{data?.map((s, i) => (
				<CollapseWrap
					className="pointer"
					headerAs="h4"
					defaultExpanded
					renderHeader={() => (
						<h4>{s.surveyName}</h4>
					)}
					style={{ marginTop: 10 }}
					key={i}
				>
					<div>
						{showAverages && (
							<CollapseWrap
								className="pointer"
								headerAs="h6"
								defaultExpanded
								renderHeader={() => (
									<h6>{t("Kysymysryhmien keskiarvot")}</h6>
								)}
								style={{ marginTop: 10 }}
							>
								{
									<SurveyBarGraph
										data={s?.data?.groupsAvg ?? s?.data?.questionsAvg ?? []}
										title={s?.surveyName}
										previous={false}
										onClick={(e) => {
											if(!e) {
												return
											}
											const correctValues = s?.data?.groupsAvg ?? s?.data?.questionsAvg ?? []
											if(s.data?.showProfileAnswers && s?.data?.something) {
												const id = correctValues?.[e.activeTooltipIndex]?.id
												let value = s.data?.questionGroups?.find(dqG => dqG.id === id)
												if(!value) {
													value = { questions: [s.data?.questions?.find(q => q.id === id)] }
												}
												setSelected3({ title: e?.activeLabel, data: s.data?.pAnswers, questionGroup: value, })
												setShow3(true)
											} else {
												setSelected2({ title: e?.activeLabel, data: correctValues?.[e.activeTooltipIndex]?.questions })
												setShow2(true)
											}
										}}
									/>
								}
							</CollapseWrap>
						)}
						{showAnswerDistribution && (
							<CollapseWrap
								className="pointer"
								headerAs="h6"
								defaultExpanded
								renderHeader={() => (
									<h6>{t("Vastausten jakautuminen")}</h6>
								)}
								style={{ marginTop: 10 }}
							>
								{getCorrectValues(s).map(qG => (
									<SurveyDistributionBarGraph
										data={qG.questions}
										title={qG?.name}
										key={qG.id ?? qG?.questions[0]?.id}
										id={qG.id}
										onClick={(e, currentId) => {
											if(!e) {
												return
											}
											const correctValues = s?.data?.groupsN ?? s?.data?.questionsN ?? []
											if(s.data?.showProfileAnswers) {
												let id = correctValues?.find(q => q.id === currentId)?.id
												if(!id) {
													id = correctValues?.[e.activeTooltipIndex]?.id
												}
												let value = s.data?.questionGroups?.find(dqG => dqG.id === id)
												if(!value) {
													value = { questions: [s.data?.questions?.find(q => q.id === id)] }
												}
												setSelected3({ title: e?.activeLabel, data: s.data?.pAnswers, questionGroup: value, })
												setShow3(true)
											} else {
												setSelected({ title: e?.activeLabel, data: qG.questions?.[e?.activeTooltipIndex]?.choices })
												setShow(true)
											}
										}}
									/>
								))}
							</CollapseWrap>
						)}
					</div>
				</CollapseWrap>
			))}
			<QuestionsChartModal show={show} setShow={setShow} data={selected.data} title={selected.title} />
			<QuestionsChartModal show={show2} setShow={setShow2} data={selected2.data} title={selected2.title} type="avg" />
			<QuestionAnswersModal show={show3} setShow={setShow3} data={selected3.data} title={selected3.title} questionGroup={selected3.questionGroup} pageName={pageName} />
		</div>
	)
}

export default SurveyDistributionCharts
