import { format, parseISO } from "date-fns"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts"
import { barColorConfig } from "./utils/barColorConfig"
import { getNotationsByQuestionGroup, getTargetLevelColorForSurveyBar, getTargetLevelColorsByNotationIndex, notationByQuestionGroupMap } from "@/constants/surveyBarColors"
import TargetLevelChartsLabel from "../TargetLevelChartsLabel"

let selectedTooltip

const CustomGraphTooltip = ({ payload, surveyTitle }) => {
	const { t } = useTranslation()

	const correctPayload = payload?.[0]?.payload?.[selectedTooltip]
	if (!correctPayload) {
		return null
	}

	const questionGroupLabel = payload?.[0]?.payload?.tag

	return (
		<div className="py-2 shadow-sm" style={{ textAlign: "center", paddingInline: "5rem", backgroundColor: "white" }}>
			<span>{format(parseISO(correctPayload.date), "dd.MM.yyyy")}</span>
			<br />
			<span>{correctPayload?.location ?? t("Ei sijaintia")}</span>
			<br/>
			<br/>
			<div style={{ color: "black" }}>
				<span style={{ fontWeight: "bold" }}>{t("Vastaus")}: {correctPayload?.value}</span>
			</div>
			{/* Target level label can be added here when desired */}
			<br />
		</div>
	)
}

const SurveyGraphDrawer = ({ surveyTitle, data, graphMargins }) => {
	return (
		<BarChart data={data} style={{ cursor: "pointer" }} margin={graphMargins} maxBarSize={100} barCategoryGap={"20%"} barGap={0}>
			<Tooltip
				filterNull={true}
				content={(props) => <CustomGraphTooltip {...props} surveyTitle={surveyTitle} />}
			/>
			<YAxis type="number" domain={[0, "dataMax"]} ticks={[0, 1, 2, 3, 4, 5]} tickCount={5} width={20} />
			<XAxis dataKey={"tag"} fontSize={11} minTickGap={-1} />
			{/* Second latest data */}
			<Bar dataKey="secondLatest.value" onMouseOver={() => selectedTooltip = "secondLatest"}>
				{data.map((answer, i) => answer?.secondLatest?.value && <Cell key={i} fill={barColorConfig.get(answer?.secondLatest?.value)?.colorLight} />)}
			</Bar>
			{/* Latest data */}
			<Bar dataKey="latest.value" onMouseOver={() => selectedTooltip = "latest"}>
				{data.map((answer, i) => answer?.latest?.value && <Cell key={i} fill={barColorConfig.get(answer?.latest?.value)?.color} />)}
			</Bar>
		</BarChart>
	)
}

export default SurveyGraphDrawer
