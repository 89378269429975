import { useTranslation } from "react-i18next"



const InvalidAccess = () => {

	const { t } = useTranslation()

	return (
		<p className="fw-bold fs-4">
			{t("Sinulla ei ole pääsyä sivulle nykyisellä valitulla roolilla. Roolivalintaa voi mukauttaa vasemmassa paneelissa, mikäli omistat useita rooleja.")}
		</p>
	)
}

export default InvalidAccess