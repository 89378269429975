import _ from "lodash"
import { useState } from "react"

const useLegendGraphToggling = () => {
	// Will be a bar data key
	const [barState, setBarState] = useState({})

	const setBarStateByDataKey = (dataKey, newState) => 
		setBarState({ ...barState, [dataKey]: { ...(barState?.[dataKey] ?? {}), ...newState } })

	const handleLegendClick = ({ dataKey }) => {
		const isCurrentlyFiltered =  !!_.get(barState, [dataKey, "filtered"])
		if (isCurrentlyFiltered) {
			setBarStateByDataKey(dataKey, { filtered: false })
		} else {
			setBarStateByDataKey(dataKey, { filtered: true })
		}
	}

	const handleLegendMouseEnter = ({ dataKey }) => {
		setBarStateByDataKey(dataKey, { hover: true })
	}

	const handleLegendMouseLeave = ({ dataKey }) => {
		setBarStateByDataKey(dataKey, { hover: false })
	}

	const isHidden = (dataKey) => {
		return _.get(barState, [dataKey, "filtered"]) === true
	}

	const isHovered = (dataKey) => {
		return _.get(barState, [dataKey, "hover"]) === true
	}

	return { barState, handleLegendClick, handleLegendMouseEnter, handleLegendMouseLeave, isHidden, isHovered }
}

export default useLegendGraphToggling
