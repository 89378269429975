import { Form, Table } from "react-bootstrap"
import DashboardTableValueFormatter from "../TableValueFormatter"
import { useTranslation } from "react-i18next"
import { formatRuleCriteria } from "../utils/rules"
import { endOfYear, format, getMonth, getYear, startOfYear } from "date-fns"
import _ from "lodash"
import PositiveIntegerInput from "@components/Common/Inputs/PositiveIntegerInput"

const ClubSupportTable = ({ data, previous: _previous, edit, value, setValue }) => {

	return <Table size="sm" className="club-support-table">
		<tbody>
			{
				(data.sections || []).map((section, sectionIndex) => {

					let previous
					if (!section.columnDisplay.includes("previousSeason")) {
						previous = null
					} else {
						previous = _previous
					}

					const rows = []
					rows.push(
						<ClubSupportTableHeader 
							key={sectionIndex} 
							title={section.title} 
							currentPeriod={data.period} 
							previousPeriod={previous?.period}
							columnDisplay={section.columnDisplay}
						/>
					)
					rows.push(
						Object.keys(section.values).map((key, keyIndex, array) => {
							return (
								<ClubSupportTableRow 
									dataObj={section.values[key]}
									previousValue={previous?.sections[sectionIndex]?.values[key]}
									hasPreviousColumn={previous}
									isLastRow={keyIndex === array.length - 1}
									appendToValue={<ClubSupportTableValueLink field={key} />}
									key={key}
									field={key}
									fieldValue={value[key] ?? ""}
									setFieldValue={v => setValue(prev => ({ ...prev, [key]: v }))}
									edit={edit}
								/>
							)})
					)					
					return rows
				})
			}
		</tbody>
	</Table>
}

const ClubSupportTableValueLink = ({ field }) => {
	const { t } = useTranslation()
	const links = {
		academyPlayersTestSuccess: "/charts/group",
		talentPlayersTestSuccess: "/charts/group",
		academyPlayersTotalMinutes: "/charts/exercise",
		talentPlayersTotalMinutes: "/charts/exercise",
		academyPlayersPlayMinutesTotal: "/charts/exercise",
		talentPlayersPlayMinutesTotal: "/charts/exercise",
	}
	const link = links[field]
	if ( link ) {
		return <div>
			<a href={link} style={{ fontSize: "0.7em" }}>
				{t("Katso tarkemmin")}
			</a>
		</div>
	}
}

const ClubSupportTableRow = ({ field, fieldValue, setFieldValue, dataObj, previousValue, hasPreviousColumn, isLastRow, appendToValue, edit }) => {
	const { t } = useTranslation()
	const style = isLastRow ? {paddingBottom: 50, borderBottom: 0} : undefined
	const { rule } = dataObj
	return <tr>
		<td style={style}>{t(rule.label)}</td>
		<td style={style}>{formatRuleCriteria({ t, rule })}</td>
		{ hasPreviousColumn ? <DashboardTableValueFormatter style={style} dataObj={previousValue} /> : <td style={style}></td>}
		{(edit && rule.adminAssigns) ?
			<ClubSupportTableInput edit={edit} field={field} fieldValue={fieldValue} setFieldValue={setFieldValue} dataObj={dataObj} /> :
			<DashboardTableValueFormatter style={style} dataObj={dataObj} append={appendToValue} />}
	</tr>
}

const ClubSupportTableInput = ({ field, fieldValue, setFieldValue={setFieldValue}, dataObj }) => {
	const { t } = useTranslation()
	const { rule } = dataObj
	const isBool = _.isBoolean(rule.value)
	if (isBool) {
		return <td>
			<Form.Group style={{maxWidth: 140}}>
				<Form.Check
					name={field + "true"}
					type="radio"
					inline
					label={t("Kyllä")}
					checked={fieldValue}
					onChange={e => setFieldValue(true)}
				/>
				<Form.Check
					name={field + "false"}
					type="radio"
					inline
					label={t("Ei")} 
					checked={!fieldValue}
					onChange={e => setFieldValue(false)}
				/>
			</Form.Group>
		</td>
	}
	return <td>
		<Form.Group style={{maxWidth: 140}}>
			<PositiveIntegerInput value={fieldValue} setValue={(v) => setFieldValue(v)} min={0} max={9999} />
		</Form.Group>
	</td>
}

const ClubSupportTableHeader = ({ title, currentPeriod, previousPeriod, columnDisplay }) => {
	const { t } = useTranslation()

	const getSeason = d => getMonth(d) < 6 ? t("Kevät") : t("Syksy")

	const titleStyle = {fontWeight: "500"}
	return <tr>
		<th style={ titleStyle }>{t(title)}</th>
		<th style={ titleStyle }>{t("Kriteeri")}</th>
		{ 
			previousPeriod ?
				<th style={ titleStyle }>
					{columnDisplay.includes("currentYear") ? "" : `${getSeason(new Date(previousPeriod.to))} ${getYear(new Date(previousPeriod.to))}`}
					{<div style={{ fontSize: "0.7em" }}>
						{format(new Date(previousPeriod.from), "dd.MM.yyyy")} - {format(new Date(previousPeriod.to), "dd.MM.yyyy")}
					</div>}
				</th> :
				<th>
				</th>
		}
		<th style={ titleStyle }>
			{columnDisplay.includes("currentYear") ? t("Vuosi {{y}}", { y: getYear(new Date(currentPeriod.to)) }) : `${getSeason(new Date(currentPeriod.to))} ${getYear(new Date(currentPeriod.to))}`}
			{ 
				<div style={{ fontSize: "0.7em" }}>
					{columnDisplay.includes("currentYear") ? 
						<span>{format(startOfYear(new Date()), "dd.MM.yyyy")} - {format(new Date(), "dd.MM.yyyy")}</span> : 
						<span>{format(new Date(currentPeriod.from), "dd.MM.yyyy")} - {format(new Date(currentPeriod.to), "dd.MM.yyyy")}</span>}
				</div>
			}
		</th>
	</tr>
}

export default ClubSupportTable