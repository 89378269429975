import { Link } from "react-router-dom"

const ClubTitleHeader = ({ club }) => {

	return (
		<th style={{ cursor: "pointer" }}>
			<Link to={`/ffa-club-support/dashboard/${club.rootGroup.id}`}>{club.rootGroup.name}</Link>
		</th>
	)
}

export default ClubTitleHeader