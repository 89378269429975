const { IconButton } = require("@mui/material")
const { default: format } = require("date-fns/format")
const { useEffect, useState } = require("react")
const { NavDropdown, ListGroup } = require("react-bootstrap")

import { TZ } from "@/const"
import Icon from "@components/Common/Icon"
import { formatInTimeZone } from "overrides"
import { useTranslation } from "react-i18next"

/**
 * Reusable event picker component
 * 
 * Expects events to have following attributes:
 * id or _id,
 * name,
 * startTime,
 * location,
 * locationCustom
 */
const EventPicker = ({ events, event, onSelect, disabled, notFoundPrefix="Ei testitapahtumia vuonna", highlightIds=[], nullOption }) => {
	const _event = (event || (nullOption ? null : events[0]))

	const { t } = useTranslation()

	const getYear = event => {
		if (!event) {
			if (nullOption) {
				return parseInt(formatInTimeZone(new Date(), TZ, "yyyy"))
			}
			return null
		}

		return parseInt(formatInTimeZone(new Date(event.startTime), TZ, "yyyy"))
	}

	const [year, setYear] = useState(getYear(_event))

	useEffect(() => {
		setYear(getYear(_event))
	}, [_event])

	const filteredEvents = events.filter(event => getYear(event) === year)

	const chevronStyle = {
		color: "gray",
		fontSize: "1.4em",
		width: 24,
		height: 24,
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}

	const _location = _event?.location?.name ?? _event?.locationCustom

	return <NavDropdown className="event-picker" disabled={disabled || (!_event && !nullOption)} title={
		_event ? <div>
			<div>{_event?.name}</div>
			<div style={{ fontSize: "0.8em" }}>
				{formatInTimeZone(new Date(_event?.startTime), TZ, "dd.MM.yyyy HH:mm")}{_location ? `, ${_location}`:""}
			</div>
		</div>
			:
			(nullOption ?
				<div>
					<div>-</div>
					<div style={{ fontSize: "0.8em" }}>
						{t("Ei tapahtumaa")}
					</div>
				</div>
				:
				<div>
					<div>{t("Ei tapahtumia")}</div>
					<div style={{ fontSize: "0.8em" }}>
						{t("Tapahtumia ei löytynyt")}
					</div>
				</div>)
	}>
		<NavDropdown.ItemText>
			<div className="p-2" style={{
				fontWeight: 500,
				display: "flex",
				alignItems: "center",
				borderBottom: "1px solid #ddd",
				backgroundColor: "white"
			}}>
				<div style={{ flex: 1, paddingLeft: 8 }}>{year}</div>
				<IconButton size="small" className="ms-auto" onClick={() => setYear(year - 1)}>
					<div style={chevronStyle}>
						<Icon name="chevron_left" />
					</div>
				</IconButton>
				<div style={{ width: 18 }}></div>
				<IconButton size="small" className="ms-auto" onClick={() => setYear(year + 1)}>
					<div style={chevronStyle}>
						<Icon name="chevron_right" />
					</div>
				</IconButton>
			</div>
			<ListGroup>
				{nullOption && <ListGroup.Item active={_event === null} onClick={() => onSelect && onSelect(null)}>
					<div>-</div>
					<div style={{ fontSize: "0.8em" }}>{t("Ei tapahtumaa")}</div>
				</ListGroup.Item>}
				{filteredEvents.map(event => {
					const location = event.location?.name ?? event.locationCustom
					const isSelected = (event.id ?? event._id) === (_event?.id ?? _event?._id)
					const isHighlighted = highlightIds.includes(event.id ?? event._id)
					return <ListGroup.Item style={(isHighlighted && !isSelected) ? { backgroundColor: "lightyellow"} : undefined} key={event.id ?? event._id} active={isSelected} onClick={() => onSelect && onSelect(event)}>
						<div>{event.name}</div>
						<div style={{ fontSize: "0.8em" }}>{formatInTimeZone(new Date(event.startTime), TZ, "dd.MM.yyyy HH:mm")}{location ? `, ${location}`:""}</div>
					</ListGroup.Item>}
				)}
				{
					!filteredEvents.length && <ListGroup.Item style={{ cursor: "default" }}>{notFoundPrefix} {year}.</ListGroup.Item>
				}
			</ListGroup>
		</NavDropdown.ItemText>
	</NavDropdown>
}

export default EventPicker