import React from "react"
import { useTranslation } from "react-i18next"
import NoResultOverlay from "../NoResultOverlay"
import { Bar, BarChart, Brush, CartesianGrid, Legend, ResponsiveContainer, Text, Tooltip, XAxis, YAxis } from "recharts"
import _ from "lodash"
import { Col, Row } from "react-bootstrap"

const color = "#4A72A7"
const brushWidth = 8

// HSL = Hue, Saturation, Lightness
const legendConfig = [
	{
		color: "hsl(274, 100%, 34%)",
	},
	{
		color: "hsl(274, 100%, 85%)",
	},
	{
		color: "hsl(178, 85%, 46%)",
	},
	{
		color: "hsl(219, 100%, 40%)",
	},
	{
		color: "hsl(219, 100%, 20%)",
	},
]

const SurveyDistributionBarGraph = ({ title, data, onClick, id }) => {
	const { t } = useTranslation()

	const noResults = _.isEmpty(data)

	// Custom component is used due to `formatter` prop in tooltip not being called on null values
	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<div className="text-center" style={{ width: 600, background: "white" }}>
					<div style={{ color: "black" }}>
						<p>{t("Kysymys")}: {payload[0]?.payload?.name}</p>
					</div>
					<Row className="p-1">
						{payload.map((p, i) => (
							<Col lg={6} key={i} className="border overflow-auto">
								<div style={{ textAlign: "center",  padding: 10, minWidth: 200, maxWidth: 400 }}>
									<div style={{ color: "black" }}> 
										<span>{_.get(p?.payload, p?.name)}</span>
									</div>
									<br />
									<div style={{ color: "black" }}>
										<span style={{ fontWeight: "bold" }}>{t("Vastauksia")}: {p?.value}</span>
									</div>
								</div>
							</Col>
						))}
					</Row>
				</div>
			)
		}   
		return null
	}

	const calculateBars = (choices) => {
		return choices.map((c, i) => {
			return <Bar key={i} name={`choices[${i}].name`} dataKey={`choices[${i}].n`} fill={legendConfig[i % choices.length].color} />
		})
	}

	const CustomizedAxisTick = (props) => {
		const { x, y, payload, width, visibleTicksCount, height } = props
		const textWidth = width / visibleTicksCount
		return (
			<Text x={x} y={y} width={textWidth * 0.75} height={height} textAnchor="middle" verticalAnchor="start">{_.truncate(payload.value, { length: 100, omission: "..." })}</Text>
		)
	  }

	const choices = data[0]?.choices ?? []

	return (
		<div>
        	<h4 className="text-center mt-4">{title}</h4>
			<NoResultOverlay isLoading={false} noResults={noResults} text={t("Kahta tulosta ei löytynyt ryhmällä {{ title }} kyselystä.", { title })} />
			<ResponsiveContainer height={330} className="main-bar-chart-container">
				<BarChart onClick={onClick ? (e) => onClick(e, id) : null} barGap={1} data={data}>
					<CartesianGrid vertical={false} />
					<XAxis
						type="category" interval={0}
						dataKey={"name"}
						tick={CustomizedAxisTick}
						height={data.length > brushWidth ? 125 : 75}
					/>
					<YAxis />
					<Tooltip
						filterNull={false}
						content={<CustomTooltip />}
						position={{ y: -300 }}
					/>
					<Legend wrapperStyle={{ position: "relative" }} formatter={(value) => _.get(data[0], value)} />
					{calculateBars(choices)}
					{data.length > brushWidth && <Brush height={30} stroke={color} endIndex={brushWidth-1} />}
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default SurveyDistributionBarGraph