import { BACKEND_WEBSOCKET_URL } from "@/const"
import store from "@/store"
import { api } from "./api"
import { convertObjToFormData, convertObjToQueryStr } from "@utils/utils"
import _ from "lodash"

export const eventsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getEvents: builder.query({
			query: ({id, type, from, to, unfinishedTasks, eventType, hasTrainingResults, rootGroupId}) => {
				let url = `/${type}/${id}/event${convertObjToQueryStr({ from, to, ["unfinished_tasks"]: unfinishedTasks, eventType, hasTrainingResults, rootGroupId })}`
				return url
			},
			providesTags: (res, error, args) => {
				if (!Array.isArray(res)) {
					return ["Event"]
				}
				return res.map(o => ({ type: "Event", id: o._id }))
			}
		}),

		getEventsPaginated: builder.query({
			query: ({id, from, to, unfinishedTasks, rootGroupId, page, limit }) => {
				let url = `/profile/${id}/event/paginated${convertObjToQueryStr({
					from,
					to,
					["unfinished_tasks"]: unfinishedTasks,
					rootGroupId,
					page,
					limit
				})}`
				return url
			},
			providesTags: (res, error, args) => {
				return ["Event"]
			}
		}),

		getEventsCombo: builder.query({
			/**
			 * Allowed query params:
			 * [groupIds, childIds, from, to]
			 */
			query: (queryObj) => ({
				url: `/event/combo${convertObjToQueryStr(queryObj)}`
			}),
			providesTags: (res, error, args) => {
				if (!Array.isArray(res)) {
					return ["Event"]
				}
				let v = res.map(o => ({ type: "Event", id: o._id })).concat(["Event"])
				return v
			}
		}),

		getMyPeriodPlannerEvents: builder.query({
			query: (queryObj) => `/event/periodPlannerEvents${convertObjToQueryStr(queryObj)}`,
			providesTags: (result, error, args) => {
				if (error) return []
				else return result.map(doc => ({ type: "Event", id: doc._id })).concat({type: "Event", id: "LIST"})
			}
		}),

		getEventsAggDurations: builder.query({
			query: (query) => `/statistics/durationsbytype${convertObjToQueryStr(query)}`,
			providesTags: ["Event", "Diary"]
		}),
		getProfileEvents: builder.query({
			// query: profileId => `/profile/${profileId}/event`,
			query: profileId => `/profile/${profileId}/event?fast=true&location=true`,
			providesTags: (res, error, args) => res.map(o => ({ type: "Event", id: o._id }))
		}),
		getGroupEvents: builder.query({
			query: groupId => `/group/${groupId}/event?location=true`,
			providesTags: (res, error, args) => res.map(o => ({ type: "Event", id: o._id }))
		}),
		getEventById: builder.query({
			query: ({eventId, openWebSocket}) => `/event/${eventId}`,
			// Subscribe for real-time task entry count updates via WebSocket
			async onCacheEntryAdded(
				arg,
				{ updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry }
			) {

				if (!arg.openWebSocket) {
					return
				}

				let wsDic = {}
				try {
					await cacheDataLoaded

					const token = store.getState().user.token
					if (!token) {
						return
					}
					const { data: eventData } = getCacheEntry()
					const surveyTasks = eventData.tasks.filter(tsk => tsk.taskDefinition.usesDiary)

					for (let task of surveyTasks) {
						let ws 
						try {
							ws = new WebSocket(`${BACKEND_WEBSOCKET_URL}/task${convertObjToQueryStr({ id: task._id, token })}`)
						} catch (e) {
							console.error(e)
							return // Abort
						}
						

						const listener = (event) => {
							let data
							try {
								data = JSON.parse(event.data)
							} catch (e) {
								return
							}
	
							updateCachedData((draft) => {
								const taskObj = draft.tasks.find(tsk => tsk._id === task._id)
								if (taskObj) {
									Object.assign(taskObj, data)
								}
							})
						}
	
						ws.addEventListener("message", listener)

						_.set(wsDic, task._id, ws)
					}
				} catch {
					// no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
					// in which case `cacheDataLoaded` will throw
				}
				// cacheEntryRemoved will resolve when the cache subscription is no longer active
				await cacheEntryRemoved
				// perform cleanup steps once the `cacheEntryRemoved` promise resolves
				_.values(wsDic).forEach(ws =>
					ws.close()
				)
			},
			providesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		getEventParticipants: builder.query({
			query: eventId => `/event/${eventId}/participants`,
			providesTags: ["Participant", "Event"]
		}),
		getEligibleParticipants: builder.query({
			query: eventId => `/event/${eventId}/eligible_participants`,
			providesTags: (response, error, args) => {
				return ["Profile", "Participant"]
			}
		}),
		getEligibleParticipantsPaginated: builder.query({
			query: ({ eventId, query }) => `/event/${eventId}/eligible_participants/search${convertObjToQueryStr(query)}`,
			providesTags: (response, error, args) => {
				return ["Profile", "Participant"]
			}
		}),
		getSurveyEvents: builder.query({
			query: (query) => ({
				url: `/event/surveys${convertObjToQueryStr(query)}`,
				method: "GET"
			}),
			providesTags: (res, error, args) => {
				return ["Event"]
			}
		}),
		addEvent: builder.mutation({
			query: ({body}) => ({
				url: "/event",
				method: "POST",
				body,
			}),
			invalidatesTags: (response, error, args) => {
				return ["Event", "Profile", "Diary"]
			}
		}),
		addEventWithVideos: builder.mutation({
			query: ({ body }) => {
				const formData = convertObjToFormData(body, true)

				return {
					url: `/event${convertObjToQueryStr({ rootGroupId: body.rootGroup })}`,
					body: formData,
					method: "POST"
				}
			},
			invalidatesTags: (result, error, args) => {
				return ["Event", "Profile", "Diary", "RootGroup", "Video"]
			}
		}),
		updateEvent: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }, "Event", "Diary"]
		}),
		updateEventWithVideos: builder.mutation({
			query: ({ eventId, body }) => {
				const formData = convertObjToFormData(body)
				return {
					url: `/event/${eventId}`,
					method: "PUT",
					body: formData,
				}
			},
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }, "Event", "Diary", "Video"]
		}),
		deleteEvent: builder.mutation({
			query: eventId => ({
				url: `/event/${eventId}`,
				method: "DELETE",
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args }, "Event", "Diary"]
		}),
		deleteRecurringEventsDeprecated: builder.mutation({
			query: eventId => ({
				url: `/event/${eventId}/recurring`,
				method: "DELETE",
			}),
			invalidatesTags: (response, error, args) => {
				if (Array.isArray(response))
					return response.map(id => ({type: "Event", id: id})).concat(["Diary"])
			}
		}),
		acceptEvent: builder.mutation({
			query: ({eventId, profileId}) => ({
				url: `/event/${eventId}/accept/${profileId}`,
				method: "POST",
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		declineEvent: builder.mutation({
			query: ({eventId, profileId}) => ({
				url: `/event/${eventId}/decline/${profileId}`,
				method: "POST",
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		getAnyParticipant: builder.query({
			query: ({eventId, query}) => ({
				url: `/event/${eventId}/participant${convertObjToQueryStr(query)}`,
				method: "GET",
			}),
		}),
		addParticipant: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}/assign`,
				method: "POST",
				body: body
			}),
			invalidatesTags: ["TrainingResult", "Participant", "EventList", "Event", "Diary"]
		}),
		removeParticipant: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}/unassign`,
				method: "POST",
				body: body
			}),
			invalidatesTags: ["TrainingResult", "Participant", "EventList", "Event", "Diary"]
		}),
		addParticipantMultiple: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}/assign/multiple`,
				method: "POST",
				body: body,
			}),
			invalidatesTags: ["TrainingResult", "Participant", "EventList", "Event", "Diary"]
		}),
		updateDiary: builder.mutation({
			query: ({profileId, eventId, body, taskId}) => ({
				url: `/profile/${profileId}/event/${eventId}/diary${taskId ? ("?task=" + taskId) : ""}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res, error, args) => ["Event", "Diary"]
		}),
		updateDiaryMultiple: builder.mutation({
			query: ({profileId, eventId, body, taskId}) => ({
				url: `/profile/${profileId}/event/${eventId}/diary/multiple${taskId ? ("?task=" + taskId) : ""}`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res, error, args) => ["Event", "Diary"]
		}),
		updateAllowEditing: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}/allow_editing`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }, "Diary"]
		}),
		updateAttendance: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}/attendance`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }, "Diary"]
		}),
		updateParticipantMatchData: builder.mutation({
			query: ({eventId, body}) => ({
				url: `/event/${eventId}/updateParticipantMatchData`,
				method: "PUT",
				body,
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		finalize: builder.mutation({
			query: (eventId) => ({
				url: `/event/${eventId}/finalize`,
				method: "POST"
			}),
			invalidatesTags: (res, error, args) => ["Event", "Diary", "TrainingResult"]
		}),
		revertFinalize: builder.mutation({
			query: (eventId) => ({
				url: `/event/${eventId}/finalize/revert`,
				method: "POST"
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args }, "Diary", "TrainingResult"]
		}),
		assignTask: builder.mutation({
			query: ({ eventId, taskId, profileId }) => ({
				url: `/event/${eventId}/task/${taskId}/assignToProfile/${profileId}`,
				method: "POST"
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		clearTaskAssign: builder.mutation({
			query: ({ eventId, taskId }) => ({
				url: `/event/${eventId}/task/${taskId}/clearAssignedProfile`,
				method: "POST"
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		getTaskOverview: builder.query({
			query: ({ query }) => ({
				url: `/event/taskOverview${convertObjToQueryStr(query)}`,
				method: "GET"
			}),
			providesTags: (res, error, args) => ["Event"]
		}),
		updateEditOverrideProfiles: builder.mutation({
			query: ({ eventId, taskId, body}) => ({
				url: `/event/${eventId}/task/${taskId}/setEditOverride`,
				method: "POST",
				body
			}),
			invalidatesTags: (res, error, args) => [{ type: "Event", id: args.eventId }]
		}),
		createRecurringEvents: builder.mutation({
			query: ({ eventData, recurrenceData }) => ({
				url: "/eventRecurrence/events",
				method: "POST",
				body: { eventData, recurrenceData }
			}),
			invalidatesTags: (res, error, args) => {
				let v = res?.events?.map(e => ({ id: e._id, type: "Event"})).concat(["Event"]) ?? ["Event"]
				return v
			}
		}),
		updateRecurringEvents: builder.mutation({
			query: ({ eventData, recurrenceData, updateFinalized, id }) => ({
				url: `/eventRecurrence/${id}/events${updateFinalized ? "?updateFinalized=1" : ""}`,
				method: "PUT",
				body: { eventData, recurrenceData }
			}),
			invalidatesTags: (res, error, args) => {
				let v = res?.events?.map(e => ({ id: e._id, type: "Event"})).concat(["Event"]) ?? ["Event"]
				return v
			}
		}),
		deleteRecurringEvents: builder.mutation({
			query: ({ id, updateFinalized }) => ({
				url: `/eventRecurrence/${id}/events${updateFinalized ? "?updateFinalized=1" : ""}`,
				method: "DELETE"
			}),
			invalidatesTags: (res, error, args) => ["Event"]
		}),
		deletePeriodPlanEvent: builder.mutation({
			query: ({ id, body }) => ({
				url: `event/${id}/period_plan`,
				method: "DELETE",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "PeriodPlanner", id: "LIST" }]
			},
		})
	})
	
})

export const { 
	useGetEventsQuery,
	useGetEventsPaginatedQuery,
	useGetEventsComboQuery,
	useGetMyPeriodPlannerEventsQuery,
	useLazyGetEventsComboQuery,
	useGetEventsAggDurationsQuery,
	useGetProfileEventsQuery,
	useGetGroupEventsQuery,
	useGetEventByIdQuery,
	useGetEligibleParticipantsQuery,
	useGetEligibleParticipantsPaginatedQuery,
	useGetSurveyEventsQuery,
	useLazyGetEligibleParticipantsPaginatedQuery,
	useLazyGetEligibleParticipantsQuery,
	useGetEventParticipantsQuery,
	useAddEventMutation,
	useAddEventWithVideosMutation,
	useUpdateEventMutation,
	useUpdateEventWithVideosMutation,
	useDeleteEventMutation,
	useDeleteRecurringEventsMutation,
	useAcceptEventMutation,
	useDeclineEventMutation,
	useLazyGetAnyParticipantQuery,
	useAddParticipantMutation,
	useRemoveParticipantMutation,
	useAddParticipantMultipleMutation,
	useUpdateDiaryMutation,
	useUpdateDiaryMultipleMutation,
	useUpdateAllowEditingMutation,
	useUpdateAttendanceMutation,
	useUpdateParticipantMatchDataMutation,
	useFinalizeMutation,
	useRevertFinalizeMutation,
	useAssignTaskMutation,
	useClearTaskAssignMutation,
	useGetTaskOverviewQuery,
	useUpdateEditOverrideProfilesMutation,
	useCreateRecurringEventsMutation,
	useUpdateRecurringEventsMutation,
	useDeleteRecurringEventsDeprecatedMutation,
	useDeletePeriodPlanEventMutation,
} = eventsApi