import _ from "lodash"
import { useTranslation } from "react-i18next"

const { useState } = require("react")
const { Col } = require("react-bootstrap")
const { ResponsiveContainer } = require("recharts")
const { default: SurveyGraphDrawer } = require("./SurveyGraphDrawer")
const { ChevronDown, ChevronRight } = require("react-bootstrap-icons")
const { default: FreeFormAnswersTable } = require("./FreeFormAnswersTable")

const GRAPH_MARGINS = {
	left: 20,
	top: 20 
}

const SurveyContainer = ({ survey }) => {
	const { t } = useTranslation()

	const title = survey?.surveyName
	const answers = survey?.answersByTag
	const SHOULD_ENABLE_FREE_FORM_ANSWERS = survey?.includesFreeForms
	// State can't be changed if `SHOULD_ENABLE_FREE_FORM_ANSWRERS` evaluates to false
	const [showFreeFormAnswers, setShowFreeFormAnswers] = useState(false)

	if (_.isEmpty(survey?.answers)) {
		return <div className="text-center">
			<h5>{t("Yhtä tulosta ei löytynyt {{ title }} kyselystä", { title })}</h5>
		</div>
	}

	return (
		<Col className="my-3" style={{ width: "100%" }}>
			<h2 className="text-center">{title}</h2>
			<ResponsiveContainer width={"97%"} height={400}>
				{SurveyGraphDrawer({ surveyTitle: title, data: answers, graphMargins: GRAPH_MARGINS })}
			</ResponsiveContainer>

			{SHOULD_ENABLE_FREE_FORM_ANSWERS && (
				<>
					<h3 className="pointer d-inline-block" style={{ marginLeft: GRAPH_MARGINS.left }}>
						<a className="d-flex text-decoration-none user-select-none align-items-center gap-2 mb-3" onClick={() => setShowFreeFormAnswers(prev => !prev)}>
							{t("Avoimet vastaukset")}
							{showFreeFormAnswers ? <ChevronDown /> : <ChevronRight />}
						</a>
					</h3>
					<Col style={{ marginLeft: GRAPH_MARGINS.left }}>
						{showFreeFormAnswers && (<FreeFormAnswersTable answers={answers} />)}
					</Col>
				</>
			)}
		</Col>
	)

}

export default SurveyContainer
