import { api } from "./api"

export const analyticsApi = api.injectEndpoints({
	endpoints: (builder) => ({
		sendAnalyticsEvent: builder.mutation({
			query: ({ log, viewed, view }) => ({
				url: "/analytics",
				method: "POST",
				body: {
					log,
					viewed,
					view,
				},
			}),
		}),
	})
})

export const {
	useSendAnalyticsEventMutation
} = analyticsApi
