import { api } from "./api"
import _ from "lodash"
import { locResponse } from "./utils"

export const taskDefinitionApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getTaskDefinitions: builder.query({
			query: () => ({
				url: "/taskDefinition",
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				return result.map(doc => ({ type: "TaskDefinition", id: doc._id })).concat([{type: "TaskDefinition", id: "LIST"}, "Loc"])
			},
			transformResponse: locResponse
		}),
		getTaskDefinition: builder.query({
			query: (id) => ({
				url: `/taskDefinition/${id}`,
			}),
			providesTags: (result, error, arg) => {
				return [{ type: "TaskDefinition", id: arg }]
			}
		}),
		createTaskDefinition: builder.mutation({
			query: (body) => ({
				url: "/taskDefinition",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "TaskDefinition", id: "LIST" }]
			}
		}),
		updateTaskDefinition: builder.mutation({
			query: ({id, body}) => ({
				url: `/taskDefinition/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "TaskDefinition", id: "LIST" }, { type: "TaskDefinition", id: args.id}]
			}
		}),
		removeTaskDefinition: builder.mutation({
			query: (id) => ({
				url: `/taskDefinition/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return [{ type: "TaskDefinition", id: "LIST" }]
			}
		}),
	})
})

export const {
	useGetTaskDefinitionsQuery,
	useGetTaskDefinitionQuery,
	useCreateTaskDefinitionMutation,
	useUpdateTaskDefinitionMutation,
	useRemoveTaskDefinitionMutation,
} = taskDefinitionApi
