import { convertObjToQueryStr } from "@utils/utils"
import { api } from "./api"
import _ from "lodash"
import { IMAGE_URL } from "@/const"

export const videoApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getVideo: builder.query({
			query: (id) => ({
				url: `/video/${id}`,
			}),
			providesTags: (result, error, args) => {
				return [{ type: "Video", id: args }]
			}
		}),
		getVideoWithPermissions: builder.query({
			query: (id) => ({
				url: `/video/${id}?withPermissions=true`,
			}),
			providesTags: (result, error, args) => {
				return [{ type: "Video", id: args }]
			}
		}),
		getVideos: builder.query({
			query: () => ({
				url: "/video",
			}),
			providesTags: (result, error, arg) => {
				return ["Video"]
			}
		}),
		search: builder.query({
			query: ({ query }) => ({
				url: `/video/search/${convertObjToQueryStr(query)}`,
			}),
			providesTags: (result, error, args) => {
				return ["Video"]
			}
		}),
		updateVideo: builder.mutation({
			query: ({ id, body }) => ({
				url: `/video/${id}`,
				method: "PUT",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", { type: "Video", id: args.id }]
			}
		}),
		deleteVideo: builder.mutation({
			query: (id) => ({
				url: `/video/${id}`,
				method: "DELETE",
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", { type: "Video", id: args }]
			}
		}),
		uploadGeneral: builder.mutation({
			query: ({ body }) => ({
				url: "/video/upload/general",
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", { type: "Video", id: result?._id }]
			}
		}),
		uploadRootGroup: builder.mutation({
			query: ({ id, body }) => ({
				url: `/video/upload/rootGroup/${id}`,
				method: "POST",
				body
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", { type: "Video", id: args.id }]
			}
		}),
		linkToEvent: builder.mutation({
			query: ({ videoId, rootGroupId, body }) => ({
				url: `/video/${videoId}/link/${rootGroupId}`,
				method: "POST",
				body: body
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", { type: "Video", id: args.videoId }].concat(args.body?.linkedEvents?.map(eId => ({ type: "Event", id: eId })))
			}
		}),
		listGeneralVideos: builder.query({
			query: ({ query }) => ({
				url: `video/general${convertObjToQueryStr(query)}`
			}),
			providesTags: (result, error, args) => {
				if (args.query.paginate) {
					return ["Video"].concat(result?.docs?.map((v) => ({ type: "Video", id: v._id })))
				} else {
					return ["Video"].concat(result?.map((v) => ({ type: "Video", id: v._id })))
				}
			}
		}),
		listRootGroupVideos: builder.query({
			query: ({ id, query }) => ({
				url: `video/rootGroup/${id}${convertObjToQueryStr(query)}`
			}),
			providesTags: (result, error, args) => {
				let videos = result?.docs ? _.cloneDeep(result.docs) : _.cloneDeep(result)
				if (videos?.general && videos?.rootGroup) {
					const generalVids = videos?.general ?? []
					const rootGroupVids = videos?.rootGroup ?? []

					videos = generalVids.concat(rootGroupVids)
				}

				return ["Video"].concat(videos.map((v) => ({ type: "Video", id: v._id })))
			}
		}),
		createIcareusAccount: builder.mutation({
			query: ({ rootGroupId, body }) => ({
				url: `/video/rootGroupAccount/${rootGroupId}/createIcareusAccount`,
				method: "POST",
				body: body
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", "RootGroup", { type: "RootGroup", id: args.rootGroupId }]
			}
		}),
		deleteIcareusAccount: builder.mutation({
			query: ({ rootGroupId }) => ({
				url: `/video/rootGroupAccount/${rootGroupId}/deleteIcareusAccount`,
				method: "POST"
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", "RootGroup", { type: "RootGroup", id: args.rootGroupId }]
			}
		}),
		restoreIcareusAccount: builder.mutation({
			query: ({ rootGroupId, organizationId }) => ({
				url: `/video/rootGroupAccount/${rootGroupId}/restoreIcareusAccount`,
				method: "POST",
				body: { organizationId }
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", "RootGroup", { type: "RootGroup", id: args.rootGroupId }]
			}
		}),
		getTrimmerToken: builder.query({
			query: (id) => ({
				url: `/video/${id}/trimmer/token`,
			})
		}),
		getEligibleEventLinkableVideos: builder.query({
			query: (queryObj) => ({
				url: `/video/getEligibleEventLinkable${convertObjToQueryStr(queryObj)}`,
			}),
			providesTags: (result, error, args) => {
				if (error)
					return []
				return result.docs.map(doc => ({ type: "Video", id: doc._id })).concat([{type: "Video", id: "LIST"}])
			}
		}),

		// Uses multipart/form-data
		uploadMultipleVideos: builder.mutation({
			query: ({ formData, query }) => ({
				url: `/video/uploadMultiple${convertObjToQueryStr(query)}`,
				body: formData,
				method: "POST"
			}),
			invalidatesTags: (result, error, args) => {
				return ["Video", "RootGroup", { type: "RootGroup", id: args.rootGroupId }]
			}
		}),

		getUsedCapacityGeneral: builder.query({
			query: () => ({
				url: "/video/capacity",
			}),
			providesTags: (result, error, args) => ["Video"]
		}),

		getUsedCapacityRootGroup: builder.query({
			query: ({ id }) => ({
				url: `/video/capacity/${id}`,
			}),
			providesTags: (result, error, args) => ["Video"]
		}),

	})
})

export const {
	useGetVideoQuery,
	useGetVideoWithPermissionsQuery,
	useGetVideosQuery,
	useUpdateVideoMutation,
	useDeleteVideoMutation,
	useUploadGeneralMutation,
	useUploadRootGroupMutation,
	useLinkToEventMutation,
	useLazyDownloadQuery,
	useListGeneralVideosQuery,
	useListRootGroupVideosQuery,
	useCreateIcareusAccountMutation,
	useDeleteIcareusAccountMutation,
	useRestoreIcareusAccountMutation,
	useGetTrimmerTokenQuery,
	useLazyGetEligibleEventLinkableVideosQuery,
	useUploadMultipleVideosMutation,
	useGetUsedCapacityGeneralQuery,
	useGetUsedCapacityRootGroupQuery
} = videoApi
