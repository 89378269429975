import _ from "lodash"
import i18n from "@/i18n"
import fi from "date-fns/locale/fi"
import sv from "date-fns/locale/sv"
import enGB from "date-fns/locale/en-GB"

/**
 * Returns applicable locale for current language
 * 
 * @param {String} languageCode						Optional: language code for locale
 * @returns {Object}											date-fns locale object
 */
export const getDateLocale = (languageCode) => {
	let dic = {
		fi,
		en: enGB,
		sv
	}
	return dic[languageCode || i18n.language] ?? fi
}

/**
 * Updates localized object paths to use translations for the current language. 
 * Default path values remain if no translation is found.
 * 
 * Dependent on `translations` top-level path in MongoDB object.
 * 
 * @param {Object} obj 								Object to be translated
 * @param {String} languageCode 			Optional: Language code for translations
 * @returns {Object}									Translated object
 */
export const localizeObject = (obj, languageCode) => {
	if (!obj) {
		return obj
	}
	if (!languageCode) {
		languageCode = i18n.language
	}

	if (obj.translations && languageCode !== "fi") {
		let dest = {}
		obj = _.mergeWith(dest, obj, _.get(obj, ["translations", languageCode]), (objValue, srcValue) => {
			if (srcValue === "" || srcValue == null) {
				return objValue
			}
		})
	}

	return obj
}

// Localize object alias
export const loc = (obj, languageCode) => localizeObject(obj, languageCode)

// Localize array; returns [] if arg is null
export const locList = (list, languageCode) => (list ?? []).map(o => loc(o, languageCode))
// Localize object or array; returns null if arg is null
export const locData = (data, languageCode) => _.isArray(data) ? locList(data, languageCode) : loc(data, languageCode)

/**
	 * Converts object path to `translations` subpath
	 * 
	 * @param {String} path 						Object path
	 * @param {String} lang 						Language code
	 * @returns {String}								Object path to `translations` equivalent
	 */
export const lPath = (path, lang) => {
	if (!lang) {
		throw "Second parameter `lang` required"
	}

	if (lang === "fi") {
		return path
	}
	return `translations.${lang}.${path}`
}

/**
 * Mimics the function of `Formik.getFieldProps` and returns Formik-compatible input props,
 * but uses the input language to determine the formik state paths.
 * 
 * @param {Object} formik 						Formik object
 * @param {String} path 							`_.set` and `setFieldValue` compatible path; dot notation is allowed
 * @param {String} lang 							Language code; controls path for setting and getting localized value
 * @returns {Object}									Input props
 */
export const getLangFieldProps = (formik, path, lang) => {
	const { values, setFieldValue, setFieldTouched, errors, touched } = formik

	const name = lPath(path, lang)
	const value = _.get(values, lPath(path, lang)) ?? ""
	const onChange = e => setFieldValue(lPath(path, lang), e.target.value)
	const onBlur = e => setFieldTouched(lPath(path, lang))
	const isInvalid = _.get(touched, lPath(path, lang)) && _.get(errors, lPath(path, lang))

	return { name, value, onChange, onBlur, isInvalid }
}